import Companies from "./companies"
import AddCompany from "./add_company"
import ViewCompany from "./view_company"
const companyRoutes = [
    
    {
        path: "/core/companies",
        element: <Companies />,
    },
    {
        path: "/core/companies/add_company",
        element: <AddCompany />,
    },
    {
        path: "/core/companies/view_company/:companyId",
        element: <ViewCompany />,
    }
]
export default companyRoutes