import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import Layout from "../component/layout";
import { toastify } from "../../../utils/toast";
import { makeRequest2 } from "../../../utils/makeRequest";
import { concentratorBaseUrl } from "../../../utils/urls";

const AddConcentrator = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  // Form states matching backend schema
  const [serialNumber, setSerialNumber] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [range, setRange] = useState(null);
  const [concentratorStatus, setConcentratorStatus] = useState("inStock"); // Default to inStock

  // Config states
  const [manufacturers, setManufacturers] = useState([]);

  // Base URL for manufacturer config
  const waterMeterManufacturerBaseUrl = '/api/core/water-meter-manufacturer';

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        setIsLoading(true);
        const manufacturersRes = await makeRequest2(waterMeterManufacturerBaseUrl, 'GET');
        if (manufacturersRes.success) {
          setManufacturers(manufacturersRes.data || []);
        }
      } catch (error) {
        console.error('Error fetching manufacturers:', error);
        toastify('Error loading manufacturer data', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchManufacturers();
  }, []);

  const handleSubmit = async () => {
    try {
      if (!serialNumber.trim()) {
        toastify("Serial Number is required", "error");
        return;
      }
      if (!manufacturer) {
        toastify("Manufacturer is required", "error");
        return;
      }
      if (!range || range <= 0) {
        toastify("Valid range is required", "error");
        return;
      }
  
      setIsSubmitting(true);
  
      const concentratorData = {
        serialNumber: serialNumber.trim(),
        manufacturer,
        range,
        isInstalled: concentratorStatus === "installed",
        isFaulty: concentratorStatus === "faulty",
        inStock: concentratorStatus === "inStock",
        status: "Offline" // Always set to Offline by default
      };
  
      const response = await makeRequest2(
        concentratorBaseUrl,
        "POST",
        concentratorData
      );
  
      if (response.success) {
        toastify("Concentrator added successfully", "success");
        navigate("/core/water_meter_management/concentrators");
      } else {
        toastify(response.error || "Failed to add concentrator", "error");
      }
    } catch (error) {
      console.error("Error adding concentrator:", error);
      toastify(
        "An unexpected error occurred while adding the concentrator",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClear = () => {
    setSerialNumber("");
    setManufacturer("");
    setRange(null);
    setConcentratorStatus("inStock");
  };

  if (isLoading) {
    return (
      <Layout>
        <div className="text-center p-5">
          Loading manufacturer data...
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/core/water_meter_management/concentrators">
                    Concentrators
                  </Link>
                </li>
                <li className="breadcrumb-item">Add New Concentrator</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Concentrator</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">
                        Serial Number<span style={{ color: "red" }}>*</span>
                      </label>
                      <InputText
                        className="form-control"
                        value={serialNumber}
                        onChange={(e) => setSerialNumber(e.target.value)}
                        placeholder="Enter serial number"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">
                        Manufacturer<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        value={manufacturer}
                        onChange={(e) => setManufacturer(e.target.value)}
                        disabled={isSubmitting}
                      >
                        <option value="">Select Manufacturer</option>
                        {manufacturers.map((mfr) => (
                          <option key={mfr._id} value={mfr._id}>
                            {mfr.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">
                        Range<span style={{ color: "red" }}>*</span>
                      </label>
                      <InputNumber
                        className="form-control"
                        value={range}
                        onValueChange={(e) => setRange(e.value)}
                        placeholder="Enter range"
                        disabled={isSubmitting}
                        min={0}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Concentrator Status</label>
                      <div className="mt-2">
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="inStock"
                            name="concentratorStatus"
                            value="inStock"
                            checked={concentratorStatus === "inStock"}
                            onChange={(e) => setConcentratorStatus(e.target.value)}
                            disabled={isSubmitting}
                          />
                          <label className="form-check-label" htmlFor="inStock">
                            In Stock
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="installed"
                            name="concentratorStatus"
                            value="installed"
                            checked={concentratorStatus === "installed"}
                            onChange={(e) => setConcentratorStatus(e.target.value)}
                            disabled={isSubmitting}
                          />
                          <label className="form-check-label" htmlFor="installed">
                            Installed
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="faulty"
                            name="concentratorStatus"
                            value="faulty"
                            checked={concentratorStatus === "faulty"}
                            onChange={(e) => setConcentratorStatus(e.target.value)}
                            disabled={isSubmitting}
                          />
                          <label className="form-check-label" htmlFor="faulty">
                            Faulty
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClear}
                  disabled={isSubmitting}
                >
                  Clear
                </Button>
                &nbsp;
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddConcentrator;