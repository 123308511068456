import ViewWaterMeter from './view_meter';
import AddMeter from './add_meter'
import AssignWaterMeter from './assign_meter';
import WaterMeters from './meters'
import WaterConfigs from './configs'
import Concentrators from './concentrators'
import AddConcentrator from './add_new_concentrator'
import PostDeliveryReport  from './delivery_report'
import Deliveries  from './deliveries'
import MeterDeliveryNote  from './delivery_note'

const waterMeterManagementRoutes = [
    {
        path: "/core/water_meter_management/concentrators",
        element: <Concentrators />
    },
    {
        path: "/core/water_meter_management/add_new_concentrator",
        element: <AddConcentrator />
    },
    {
        path: "/core/water_meter_management/meters",
        element: <WaterMeters />
    },
    {
        path: "/core/water_meter_management/add_new_meter",
        element: <AddMeter />
    },
    {
        path: "/core/water_meter_management/view_meter",
        element: <ViewWaterMeter />
    },
    {
        path: "/core/water_meter_management/assign",
        element: <AssignWaterMeter />

    },
    {
        path: "/core/water_meter_management/configs",
        element: <WaterConfigs />

    },
    {
        path: "/core/water_meter_management/post_delivery_report",
        element: <PostDeliveryReport/>

    },
    {
        path: "/core/water_meter_management/deliveries",
        element: <Deliveries/>

    },
    {
        path: "/core/water_meter_management/delivery_note",
        element: <MeterDeliveryNote/>

    }

]
export default waterMeterManagementRoutes