import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Layout from '../component/layout';
import { AutoComplete } from 'primereact/autocomplete';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getFacilitiesURL, waterMeterBaseUrl } from '../../../utils/urls';
import { toastify } from '../../../utils/toast';
import MeterDeliveryNote from './delivery_note';

const AssignMeter = () => {
  const [currentStep, setCurrentStep] = useState('facility');
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState('');
  const [meters, setMeters] = useState([]);
  const [filteredMeters, setFilteredMeters] = useState([]);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [concentrators, setConcentrators] = useState([]);
  const [filteredConcentrators, setFilteredConcentrators] = useState(null);
  const [selectedConcentrators, setSelectedConcentrators] = useState([]);
  const [concentratorSearchTerm, setConcentratorSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [deliveryForm, setDeliveryForm] = useState({
    deliveryDate: '',
    deliveredBy: '',
    notes: ''
  });

  // Config states for meter details
  const [manufacturers, setManufacturers] = useState([]);
  const [meterSizes, setMeterSizes] = useState([]);

  // Base URLs for configs
  const waterMeterSizeBaseUrl = '/api/core/water-meters-sizes';
  const waterMeterManufacturerBaseUrl = '/api/core/water-meter-manufacturer';

  // Base URLs for concentrators
  const concentratorBaseUrl = '/api/core/concentrators';

  const getFacilities = async () => {
    try {
      const response = await makeRequest2(getFacilitiesURL, 'GET', {});
      if (response.success) {
        setFacilities(response.data);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };

  const fetchConfigs = async () => {
    try {
      const [sizesRes, manufacturersRes] = await Promise.all([
        makeRequest2(waterMeterSizeBaseUrl, 'GET'),
        makeRequest2(waterMeterManufacturerBaseUrl, 'GET')
      ]);

      if (sizesRes.success) setMeterSizes(sizesRes.data || []);
      if (manufacturersRes.success) setManufacturers(manufacturersRes.data || []);
    } catch (error) {
      console.error('Error fetching configurations:', error);
      toastify('Error loading configuration data', 'error');
    }
  };

  const fetchMeters = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2(waterMeterBaseUrl, 'GET');
      if (response.success) {
        // Filter only un installed meters
        const uninstalledMeters = response.data.filter(meter => meter.isInstalled === false);
        setMeters(uninstalledMeters);
        setFilteredMeters(uninstalledMeters);
      }
    } catch (error) {
      toastify('Error fetching meters', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchConcentrators = async () => {
    try {
      const response = await makeRequest2(concentratorBaseUrl, 'GET');
      if (response.success) {
        setConcentrators(response.data || []);
      } else {
        toastify(response.error || 'Failed to fetch concentrators', 'error');
      }
    } catch (error) {
      toastify('An error occurred while fetching concentrators', 'error');
    }
  };

  const searchConcentrator = (event) => {
    const query = event.query.toLowerCase();
    const filtered = concentrators.filter(concentrator =>
      concentrator.serialNumber.toLowerCase().includes(query) ||
      concentrator.manufacturer.toLowerCase().includes(query)
    );
    setFilteredConcentrators(filtered);
  };

  const handleAddConcentrator = (concentrator) => {
    // Check if concentrator is already selected
    if (!selectedConcentrators.some(c => c._id === concentrator._id)) {
      setSelectedConcentrators(prev => [...prev, concentrator]);
      setConcentratorSearchTerm(''); // Reset search term
    }
  };
  const handleRemoveConcentrator = (indexToRemove) => {
    setSelectedConcentrators(prev =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };


  useEffect(() => {
    getFacilities();
    fetchConfigs();
    fetchMeters();
    fetchConcentrators();
  }, []);

  useEffect(() => {
    if (searchTerm.trim()) {
      const filtered = meters.filter(meter =>
        meter.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getManufacturerName(meter.manufacturer).toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMeters(filtered);
    } else {
      setFilteredMeters(meters);
    }
  }, [searchTerm, meters]);

  const getManufacturerName = (manufacturerId) => {
    const manufacturer = manufacturers.find(m => m._id === manufacturerId);
    return manufacturer ? manufacturer.name : 'N/A';
  };

  const getSizeName = (sizeId) => {
    const size = meterSizes.find(s => s._id === sizeId);
    return size ? size.code : 'N/A';
  };

  const handleDeliveryFormChange = (e) => {
    const { name, value } = e.target;
    setDeliveryForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFinishAssignment = async () => {
    try {
      // Find selected facility details
      const facility = facilities.find(f => f._id === selectedFacility);

      // Generate meters array with required format
      const metersData = selectedMeters.map(meter => ({
        meterId: meter._id,
        serialNumber: meter.serialNumber
      }));

      // Generate concentrators array with required format
      const concentratorsData = selectedConcentrators.map(concentrator => ({
        concentratorId: concentrator._id,
        serialNumber: concentrator.serialNumber
      }));

      // Prepare delivery data matching the schema requirements
      const deliveryData = {
        facilityId: selectedFacility,
        facilityName: facility.name,
        deliveredBy: deliveryForm.deliveredBy,
        deliveryDate: new Date(deliveryForm.deliveryDate).toISOString(),
        notes: deliveryForm.notes || '',
        concentrators: concentratorsData,
        meters: metersData,
        status: 'pending'
      };

      // Execute all updates in sequence 
      try {
        // 1. Create delivery record first
        const deliveryRes = await makeRequest2(
          '/api/core/water-meters-delivery',
          'POST',
          deliveryData
        );

        if (!deliveryRes.success) {
          throw new Error('Failed to create delivery record');
        }

        // 2. Update meters with facility ID
        const metersUpdatePromises = selectedMeters.map(meter =>
          makeRequest2(`${waterMeterBaseUrl}/${meter._id}`, 'PUT', {
            facilityId: selectedFacility,
            status: 'active'
          })
        );
        await Promise.all(metersUpdatePromises);

        // 3. Update concentrators with facility ID
        const concentratorsUpdatePromises = selectedConcentrators.map(concentrator =>
          makeRequest2(`${concentratorBaseUrl}/${concentrator._id}`, 'PUT', {
            facilityId: selectedFacility,
            isInstalled: true,
            inStock: false
          })
        );
        await Promise.all(concentratorsUpdatePromises);

        // If we get here, all operations were successful
        toastify('Assignment completed successfully', 'success');
        setCurrentStep('finish');

      } catch (error) {
        // If any operation fails, show specific error
        toastify(error.message || 'Failed to complete assignment', 'error');
      }
    } catch (error) {
      toastify(error.message || 'Failed to prepare assignment data', 'error');
    }
  };

  const handleNextClick = () => {
    const steps = ['facility', 'meter', 'concentrator', 'delivery', 'finish'];
    const currentIndex = steps.indexOf(currentStep);

    if (currentStep === 'delivery') {
      // Validate delivery form
      if (!deliveryForm.deliveryDate || !deliveryForm.deliveredBy) {
        toastify('Please fill in all required fields', 'error');
        return;
      }
      // Handle the finish process
      handleFinishAssignment();
    } else {
      setCurrentStep(steps[currentIndex + 1]);
    }
  };

  const renderFacilityStep = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Facility<span style={{ color: 'red' }}>*</span></label>
                  <select
                    className="form-control mt-2"
                    value={selectedFacility}
                    onChange={(e) => setSelectedFacility(e.target.value)}
                  >
                    <option value="">-- Select Facility --</option>
                    {facilities.map((facility) => (
                      <option key={facility._id} value={facility._id}>
                        {facility.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderMeterStep = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-md-6">
                <InputText
                  placeholder="Search by serial number or manufacturer"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 text-end">
                <span className="text-muted">
                  Selected Meters: {selectedMeters.length}
                </span>
              </div>
            </div>
            <div className="dt-responsive table-responsive">
              <DataTable
                value={filteredMeters}
                selection={selectedMeters}
                onSelectionChange={(e) => setSelectedMeters(e.value)}
                loading={loading}
                paginator
                rows={10}
                stripedRows
                emptyMessage="No inactive meters available."
                tableStyle={{ minWidth: '50rem' }}
                dataKey="_id"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="serialNumber" sortable header="Serial Number"></Column>
                <Column
                  field="manufacturer"
                  sortable
                  header="Manufacturer"
                  body={(rowData) => getManufacturerName(rowData.manufacturer)}
                ></Column>
                <Column
                  field="size"
                  sortable
                  header="Size"
                  body={(rowData) => getSizeName(rowData.size)}
                ></Column>
                <Column field="value" sortable header="Value (m³)"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderConcentratorStep = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Concentrators</label>
                  <div className="d-flex align-items-center mb-2">
                    <AutoComplete
                      value={concentratorSearchTerm}
                      suggestions={filteredConcentrators}
                      completeMethod={searchConcentrator}
                      field="serialNumber"
                      dropdown
                      forceSelection
                      className="flex-grow-1 me-2"
                      onChange={(e) => {
                        setConcentratorSearchTerm(e.value);
                      }}
                      onSelect={(e) => handleAddConcentrator(e.value)}
                      itemTemplate={(item) => (
                        <div>
                          <div><strong>S/N:</strong> {item.serialNumber}</div>
                          <div><small>Manufacturer: {item.manufacturer}</small></div>
                        </div>
                      )}
                      placeholder="Search for a concentrator..."
                    />
                  </div>

                  {selectedConcentrators.length > 0 && (
                    <div className="selected-concentrators mt-2 d-flex flex-wrap gap-2">
                      {selectedConcentrators.map((concentrator, index) => (
                        <div
                          key={concentrator._id}
                          className="badge bg-primary d-flex align-items-center p-2 me-2 mb-2"
                        >
                          <span>{concentrator.serialNumber}</span>
                          <button
                            type="button"
                            className="btn btn-link btn-sm text-white p-0 ms-2"
                            onClick={() => handleRemoveConcentrator(index)}
                            style={{ textDecoration: 'none' }}
                          >
                            <i className="ti ti-x"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderDeliveryReport = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h5>Delivery Report</h5>
                <hr />
                <div className="form-group">
                  <label>Delivery Date<span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="date"
                    name="deliveryDate"
                    className="form-control mt-2"
                    value={deliveryForm.deliveryDate}
                    onChange={handleDeliveryFormChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Enter Your Name<span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="deliveredBy"
                    className="form-control mt-2"
                    value={deliveryForm.deliveredBy}
                    onChange={handleDeliveryFormChange}
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Comments</label>
                  <textarea
                    name="notes"
                    className="form-control mt-2"
                    rows="4"
                    value={deliveryForm.notes}
                    onChange={handleDeliveryFormChange}
                    placeholder="Enter any additional comments"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderFinishStep = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body text-center">
            <i className="ti ti-circle-check" style={{ fontSize: '48px', color: '#28a745' }}></i>
            <h3 className="mt-3">Assignment Complete</h3>
            <p>The meter has been successfully assigned to the facility</p>

            <MeterDeliveryNote
              facility={facilities.find(f => f._id === selectedFacility)}
              selectedMeters={selectedMeters}
              selectedConcentrators={selectedConcentrators}
              deliveryForm={deliveryForm}
            />

            <Link to="/core/water_meter_management/meters" className="btn btn-primary mt-3">
              Back to Meters
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/core/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Water Meter Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Assign</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body py-0">
                      {/* Replace the existing nav-link with this code */}
                      <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                          <span
                            className={`nav-link ${currentStep === 'facility' ? 'active' : ''}`}
                            role="tab"
                          >
                            <i className="ti ti-building me-2"></i>
                            <span>Select Facility</span>
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link ${currentStep === 'meter' ? 'active' : ''}`}
                            role="tab"
                          >
                            <i className="ti ti-gauge me-2"></i>
                            <span>Select Meter</span>
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link ${currentStep === 'concentrator' ? 'active' : ''}`}
                            role="tab"
                          >
                            <i className="ti ti-settings me-2"></i>
                            <span>Concentrator</span>
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link ${currentStep === 'delivery' ? 'active' : ''}`}
                            role="tab"
                          >
                            <i className="ti ti-clipboard-check me-2"></i>
                            <span>Delivery Report</span>
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link ${currentStep === 'finish' ? 'active' : ''}`}
                            role="tab"
                          >
                            <i className="ti ti-check-circle me-2"></i>
                            <span>Finish</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane show active">
                      {currentStep === 'facility' && renderFacilityStep()}
                      {currentStep === 'meter' && renderMeterStep()}
                      {currentStep === 'concentrator' && renderConcentratorStep()}
                      {currentStep === 'delivery' && renderDeliveryReport()}
                      {currentStep === 'finish' && renderFinishStep()}

                      {currentStep !== 'finish' && (
                        <div className="row mt-3">
                          <div className="col-12">
                            <div style={{ float: 'right' }}>
                              {currentStep !== 'facility' && (
                                <button
                                  className="btn btn-secondary me-2"
                                  onClick={() => {
                                    const steps = ['facility', 'meter', 'concentrator', 'delivery', 'finish'];
                                    const currentIndex = steps.indexOf(currentStep);
                                    setCurrentStep(steps[currentIndex - 1]);
                                  }}
                                >
                                  Previous
                                </button>
                              )}
                              <button
                                className="btn btn-primary"
                                onClick={handleNextClick}
                                disabled={
                                  (currentStep === 'facility' && !selectedFacility) ||
                                  (currentStep === 'meter' && selectedMeters.length === 0) ||
                                  (currentStep === 'concentrator' && selectedConcentrators.length === 0) ||
                                  (currentStep === 'delivery' && (!deliveryForm.deliveryDate || !deliveryForm.deliveredBy))
                                }
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AssignMeter;