import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import Layout from '../component/layout';
import Concentrators from './concentrators';

function Deliveries() {
  const navigate = useNavigate();
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [formData, setFormData] = useState({
    receivedBy: '',
    notes: '',
    postNotes: '',
    status: 'pending'
  });

  const fetchDeliveries = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2('/api/core/water-meters-delivery', 'GET');

      if (response?.success) {
        const deliveriesData = response.data || [];
        setDeliveries(deliveriesData);
        setFilteredDeliveries(deliveriesData);
      } else {
        toastify(response?.error || 'Failed to fetch deliveries', 'error');
      }
    } catch (error) {
      console.error('Error fetching deliveries:', error);
      toastify('An error occurred while fetching deliveries', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveries();
  }, []);

  useEffect(() => {
    if (!Array.isArray(deliveries)) return;

    const filtered = deliveries.filter(delivery => {
      if (!delivery) return false;

      const deliveryNoMatch = delivery.deliveryNumber?.toString()?.includes(searchTerm) || false;
      const facilityMatch = delivery.facilityName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || false;
      const statusMatch = delivery.status?.toLowerCase()?.includes(searchTerm.toLowerCase()) || false;

      return deliveryNoMatch || facilityMatch || statusMatch;
    });

    setFilteredDeliveries(filtered);
  }, [searchTerm, deliveries]);

  const statusTemplate = (rowData) => {
    if (!rowData?.status) return null;

    const statusColors = {
      'pending': 'warning',
      'completed': 'success',
      'cancelled': 'danger'
    };

    return (
      <span className={`badge bg-${statusColors[rowData.status] || 'secondary'}`}>
        {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
      </span>
    );
  };

  // Opens the edit modal (existing)
  const handleModalOpen = (delivery) => {
    if (!delivery) return;

    setSelectedDelivery(delivery);
    setFormData({
      receivedBy: delivery.receivedBy || '',
      notes: delivery.notes || '',
      postNotes: delivery.postNotes || '',
      status: delivery.status || 'pending'
    });
    setShowModal(true);
  };

  // New function: Opens the details modal
  const handleDetailsModalOpen = (delivery) => {
    if (!delivery) return;
    setSelectedDelivery(delivery);
    setShowDetailsModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDelivery?._id) {
      toastify('Invalid delivery selected', 'error');
      return;
    }

    setLoading(true);
    try {
      const response = await makeRequest2(`/api/core/water-meters-delivery/${selectedDelivery._id}`, 'PUT', formData);
      if (response?.success) {
        toastify('Delivery updated successfully', 'success');
        await fetchDeliveries();
        setShowModal(false);
      } else {
        toastify(response?.error || 'Failed to update delivery', 'error');
      }
    } catch (error) {
      toastify('An error occurred while updating the delivery', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!id) return;

    confirmDialog({
      message: 'Are you sure you want to delete this delivery?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        try {
          const response = await makeRequest2(`/api/core/water-meters-delivery/${id}`, 'DELETE');
          if (response?.success) {
            toastify('Delivery deleted successfully', 'success');
            await fetchDeliveries();
          } else {
            toastify(response?.error || 'Failed to delete delivery', 'error');
          }
        } catch (error) {
          toastify('An error occurred while deleting the delivery', 'error');
        }
      },
    });
  };

  const handleStatusChange = async (id, newStatus) => {
    if (!id || !newStatus) return;

    confirmDialog({
      message: `Are you sure you want to mark this delivery as ${newStatus}?`,
      header: 'Confirm Status Change',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: `p-button-${newStatus === 'completed' ? 'success' : 'warning'}`,
      accept: async () => {
        try {
          const response = await makeRequest2(`/api/core/deliveries/${id}`, 'PUT', { status: newStatus });
          if (response?.success) {
            toastify(`Delivery marked as ${newStatus}`, 'success');
            await fetchDeliveries();
          } else {
            toastify(response?.error || 'Failed to update delivery status', 'error');
          }
        } catch (error) {
          toastify('An error occurred while updating the delivery status', 'error');
        }
      },
    });
  };

  const handlePostDelivery = (delivery) => {
    if (!delivery?._id || !delivery?.facilityId) {
      toastify('Invalid delivery data', 'error');
      return;
    }

    // Prepare meters array with the correct structure
    const deliveryMeters = delivery.meters.map(meter => ({
      meterId: meter.meterId,
      serialNumber: meter.serialNumber,
      _id: meter._id
    }));

    // Prepare concentrators array with the correct structure
    const deliveryConcentrators = delivery.concentrators.map(concentrator => ({
      concentratorId: concentrator.concentratorId,
      serialNumber: concentrator.serialNumber,
      _id: concentrator._id
    }));

    navigate('/core/water_meter_management/post_delivery_report', {
      state: {
        deliveryId: delivery._id,
        facilityId: delivery.facilityId,
        deliveryNumber: delivery.deliveryNumber,
        facilityName: delivery.facilityName,
        deliveredBy: delivery.deliveredBy,
        deliveryDate: delivery.deliveryDate,
        notes: delivery.notes,
        concentrators: deliveryConcentrators,
        meters: deliveryMeters  // Pass the properly structured meters array
      }
    });
  };

  // Updated actions template now includes an eye icon to view details.
  const actionsTemplate = (rowData) => {
    if (!rowData?._id) return null;

    return (
      <div className="flex gap-2">
        <Button
          icon="ti ti-eye"
          className="p-button-rounded p-button-info"
          onClick={() => handleDetailsModalOpen(rowData)}
          tooltip="View Details"
        />
        {rowData.status === 'pending' && (
          <>
            <Button
              icon="ti ti-check"
              className="p-button-rounded p-button-success"
              onClick={() => handlePostDelivery(rowData)}
              tooltip="Complete Delivery"
            />
            <Button
              icon="ti ti-x"
              className="p-button-rounded p-button-warning"
              onClick={() => handleStatusChange(rowData._id, 'cancelled')}
              tooltip="Cancel"
            />
          </>
        )}
      </div>
    );
  };

  const formatDate = (value) => {
    if (!value) return '';
    return new Date(value).toLocaleDateString();
  };

  return (
    <Layout>
      <ConfirmDialog />
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/core/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Delivery Management</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-3">
                  <div className="form-search">
                    <i className="ti ti-search"></i>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by delivery number or facility"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="dt-responsive table-responsive">
                <DataTable
                  value={filteredDeliveries}
                  emptyMessage="No deliveries found."
                  sortMode="multiple"
                  paginator
                  rows={10}
                  stripedRows
                  tableStyle={{ minWidth: '50rem' }}
                  loading={loading}
                >
                  <Column field="deliveryNumber" sortable header="Delivery No."></Column>
                  <Column field="facilityName" sortable header="Facility"></Column>
                  <Column field="deliveredBy" sortable header="Delivered By"></Column>
                  <Column field="receivedBy" sortable header="Received By"></Column>
                  <Column
                    field="deliveryDate"
                    sortable
                    header="Delivery Date"
                    body={(rowData) => formatDate(rowData.deliveryDate)}
                  ></Column>
                  <Column field="status" sortable header="Status" body={statusTemplate}></Column>
                  <Column header="Actions" body={actionsTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delivery Edit Modal */}
      {showModal && (
        <div className="modal show d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Delivery</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Received By</label>
                    <InputText
                      className="form-control"
                      value={formData.receivedBy}
                      onChange={(e) => setFormData({ ...formData, receivedBy: e.target.value })}
                      placeholder="Enter receiver's name"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Notes</label>
                    <textarea
                      className="form-control"
                      value={formData.notes}
                      onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                      placeholder="Enter notes"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Post Notes</label>
                    <textarea
                      className="form-control"
                      value={formData.postNotes}
                      onChange={(e) => setFormData({ ...formData, postNotes: e.target.value })}
                      placeholder="Enter post notes"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Delivery Details Modal */}
      {showDetailsModal && selectedDelivery && (
        <div className="modal show d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delivery Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDetailsModal(false)}
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <strong>Delivery Number:</strong>
                    <p>{selectedDelivery.deliveryNumber}</p>
                  </div>
                  <div className="col-md-6">
                    <strong>Facility Name:</strong>
                    <p>{selectedDelivery.facilityName}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Delivered By:</strong>
                    <p>{selectedDelivery.deliveredBy}</p>
                  </div>
                  <div className="col-md-6">
                    <strong>Received By:</strong>
                    <p>{selectedDelivery.receivedBy}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Delivery Date:</strong>
                    <p>{formatDate(selectedDelivery.deliveryDate)}</p>
                  </div>
                  <div className="col-md-6">
                    <strong>Status:</strong>
                    <p>{selectedDelivery.status}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Number of Meters:</strong>
                    <p>{selectedDelivery.meters?.length || 0}</p>
                  </div>
                  <div className="col-md-6">
                    <strong>Number of Concentrators:</strong>
                    <p>{selectedDelivery.concentrators?.length || 0}</p>
                  </div>
                </div>
                {selectedDelivery.notes && (
                  <div className="row">
                    <div className="col-12">
                      <strong>Notes:</strong>
                      <p>{selectedDelivery.notes}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDetailsModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Deliveries;
