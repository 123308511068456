import React, { useRef } from "react";
import styles from "./assign-meter.module.css";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const MeterDeliveryNote = ({ 
  facility, 
  selectedMeters, 
  selectedConcentrators, 
  deliveryForm 
}) => {
  const deliveryNoteRef = useRef(null);
  const toast = useRef(null);

  const handleDownloadPDF = async () => {
    const element = document.getElementById("printable-delivery-note");
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const filename = `DeliveryNote_${facility.name}_${timestamp}.pdf`;

    try {
      // Configure html2canvas
      const canvas = await html2canvas(element, {
        scale: 2, // Higher scale for better quality
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      // Configure PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Calculate dimensions
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add image to PDF
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");

      // If content spans multiple pages
      if (imgHeight > pageHeight) {
        let remainingHeight = imgHeight;
        let position = -pageHeight;

        while (remainingHeight > 0) {
          pdf.addPage();
          position -= pageHeight;
          pdf.addImage(
            imgData,
            "JPEG",
            0,
            position,
            imgWidth,
            imgHeight,
            "",
            "FAST"
          );
          remainingHeight -= pageHeight;
        }
      }

      // Save the PDF
      pdf.save(filename);

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Delivery Note downloaded successfully!",
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate PDF. Please try again.",
      });
    }
  };

  const consolidatedItems = [
    {
      description: "Water Meters",
      quantity: selectedMeters.length,
    },
    {
      description: "Concentrators",
      quantity: selectedConcentrators.length,
    },
  ];

  return (
    <div className={styles.pageContainer}>
      <Toast ref={toast} />
      <div className={styles.actionButtons}>
        <Button
          label="Download Delivery Note"
          icon="pi pi-download"
          onClick={handleDownloadPDF}
          className="p-button-primary"
        />
      </div>

      <div
        id="printable-delivery-note"
        ref={deliveryNoteRef}
        className={styles.assignMeterContainer}
      >
        <div className={styles.headerSection}>
          <div className={styles.headerTitle}>Delivery Note</div>
          <div style={{ color: "white" }}>
            <div>
              Date:{" "}
              {new Date(deliveryForm.deliveryDate).toLocaleDateString()}
            </div>
            <div>
              Total Items:{" "}
              {consolidatedItems.reduce(
                (sum, item) => sum + item.quantity,
                0
              )}
            </div>
          </div>
        </div>

        <div className={styles.formSection}>
          <div className="row">
            <div className="col-md-6">
              <div className={styles.summarySection}>
                <h6 className={styles.summaryTitle}>
                  Destination Facility
                </h6>
                <div className={styles.summaryItem}>
                  <span className={styles.summaryLabel}>Name:</span>
                  <span className={styles.summaryValue}>
                    {facility.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.summarySection}>
                <h6 className={styles.summaryTitle}>Delivery Details</h6>
                <div className={styles.summaryItem}>
                  <span className={styles.summaryLabel}>
                    Delivered By:
                  </span>
                  <span className={styles.summaryValue}>
                    {deliveryForm.deliveredBy}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th className="text-center">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {consolidatedItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.description}</td>
                    <td className="text-center">{item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Signature Section */}
          <div className={styles.signatureContainer}>
            <div className={styles.signatureSection}>
              <h6 className={styles.summaryTitle}>Delivered By</h6>
              <div>Name: {deliveryForm.deliveredBy}</div>
              <div>Signature: ________________</div>
              <div>
                Date:{" "}
                {new Date(deliveryForm.deliveryDate).toLocaleDateString()}
              </div>
            </div>
            <div className={styles.signatureSection}>
              <h6 className={styles.summaryTitle}>Received By</h6>
              <div>Name: ________________</div>
              <div>Signature: ________________</div>
              <div>Date: ________________</div>
            </div>
          </div>

          {deliveryForm.notes && (
            <div className="mt-3">
              <h6 className={styles.summaryTitle}>Additional Notes</h6>
              <p>{deliveryForm.notes}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MeterDeliveryNote;
