import Login from './login'
import ForgotPassword from './forgotPassword'
import CheckEmail from './checkEmail';
import CodeVerification from './codeVerification';
import ResetPassword from './resetPassword';
const authenticationRoutes = [
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/forgot_password",
        element: <ForgotPassword />,
    },
    {
        path: "/check_email",
        element: <CheckEmail />,
    },
    {
        path: "/code_verification",
        element: <CodeVerification />,
    },
    {
        path: "/reset_password/:userId",
        element: <ResetPassword />,
    }
]
export default authenticationRoutes