// src/components/AddFacility.js
import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { AddressAutofill } from '@mapbox/search-js-react'
import Layout from '../component/layout'
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { addFacilityURL } from '../../../utils/urls';

function AddFacility() {
    const { companyId } = useParams();
    const navigate = useNavigate()
    const [facilityName, setFacilityName] = useState('TEST');
    const [facilityLocation, setFacilityLocation] = useState('TEST')
    const [subDivision, setSubDivision] = useState('');
    const [divisionArray, setDivisionArray] = useState([])
    const [ArrayDivisions, setArrayDivisions] = useState([])
    const [numberOfDivision, setNumberofDivision] = useState(0)

    const handleSubmitButton = async () => {
        try {
            if (facilityName === '') {
                toastify('Facility Name is required', 'error')
            }

            else if (facilityLocation === '') {
                toastify('Facility Location is required', 'error')
            }
            else if (subDivision === '') {
                toastify('Sub Division is required', 'error')
            }
            else if (divisionArray.length === 0) {
                toastify('Divisions should be greater than 0', 'error')
            }
            else {
                const response = await makeRequest2(addFacilityURL + '/' + companyId, 'POST', {
                    facilityName,
                    facilityLocation,
                    subDivision,
                    divisionArray
                })
                if (response.success) {
                    toastify(response.data, 'success')
                    navigate('/core/companies/view_company/' + companyId)
                }
                else {
                    throw new Error(response.error)
                }
            }

        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Facility Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Add Facility</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h5> <Link to={"/core/companies/view_company/" + companyId}><span><i className="ti ti-arrow-narrow-left"></i> </span></Link>  Add Facility</h5>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" for="schoolLocation">Facility Name <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" placeholder="Enter facility name" value={facilityName} onChange={(e) => {
                                                            let value = e.target.value
                                                            setFacilityName(value)
                                                        }} />
                                                    </div>

                                                </div>
                                                {/* <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label className="form-label" for="schoolLocation">Land Reference Number <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" placeholder="Enter Land Reference Number" value={landReferenceNumber} onChange={(e) => {
                                                            let value = e.target.value
                                                            setLandReferenceNumber(value)
                                                        }} />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="schoolLocation">
                                                            Facility Location <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <AddressAutofill accessToken={'pk.eyJ1IjoiZXZhbnMtbmpvZ3UiLCJhIjoiY2s3dzdrM3V5MDFmNDNkcGRqZDdzcTR1eCJ9.pk-MT1CGAs8FutVkiyWs1g'}>

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Click to get location or enter manually"
                                                                value={facilityLocation}

                                                                onChange={(e) => {
                                                                    setFacilityLocation(e.target.value);
                                                                }}
                                                            />
                                                        </AddressAutofill>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" for="schoolLocation">Sub Division Type <span style={{ color: 'red' }}>*</span></label>
                                                        <select type="text" className="form-control" placeholder="Enter Subdivision Type" value={subDivision} onChange={(e) => {
                                                            let value = e.target.value;
                                                            setSubDivision(value);
                                                            setArrayDivisions([])
                                                            setDivisionArray([])
                                                            setNumberofDivision(0)
                                                        }}>
                                                            <option value="">-- Select --</option>
                                                            <option value="Phase">Phases</option>
                                                            <option value="Zone">Zones</option>
                                                            <option value="Block">Blocks</option>
                                                            <option value="Court">Courts</option>
                                                            <option value="Cluster">Clusters</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" for="schoolLocation">Number of {subDivision}(s) <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number" value={numberOfDivision} className="form-control" placeholder={`Enter number of ` + subDivision} onChange={(e) => {
                                                            let value = e.target.value;
                                                            const array = [];
                                                            let d = []
                                                            for (let i = 0; i < value; i++) {
                                                                array.push(i + 1);
                                                                d.push({
                                                                    divisionName: subDivision + ' ' + (i + 1),
                                                                    NoOfFloors: 0
                                                                })
                                                            }
                                                            setNumberofDivision(value)
                                                            setArrayDivisions(array)
                                                            setDivisionArray(d)

                                                        }} />
                                                    </div>
                                                </div>
                                                {
                                                    ArrayDivisions.map((item, index) => {
                                                        return < >
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="schoolLocation">{subDivision} Name</label>
                                                                    <input type="text" className="form-control" placeholder={divisionArray[index].divisionName} onChange={(e) => {
                                                                        let array = divisionArray
                                                                        array[index].divisionName = e.target.value
                                                                        setDivisionArray(array)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="schoolLocation">No of {subDivision === 'Block' ? 'Floors' : 'Units'}</label>
                                                                    <input type="Number" className="form-control" placeholder={divisionArray[index].NoOfFloors} onChange={(e) => {
                                                                        let array = divisionArray
                                                                        array[index].NoOfFloors = e.target.value
                                                                        
                                                                        setDivisionArray(array)
                                                                    }} />
                                                                </div>
                                                            </div>

                                                        </>
                                                    })
                                                }
                                                <div className="row">
                                                    <div className="col-md-12 text-right" >
                                                        <div style={{ float: 'right' }}>
                                                            <button onClick={handleSubmitButton} className="btn btn-primary">Submit</button>
                                                        </div>

                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}

export default AddFacility;
