import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Layout from "../component/layout";
import { toastify } from "../../../utils/toast";
import { makeRequest2 } from "../../../utils/makeRequest";
import { waterMeterBaseUrl } from "../../../utils/urls";

const AddWaterMeter = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [serialNumber, setSerialNumber] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [protocol, setProtocol] = useState("");
  const [size, setSize] = useState("");

  // Config states
  const [manufacturers, setManufacturers] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const [meterSizes, setMeterSizes] = useState([]);

  // Base URLs for configs
  const waterMeterSizeBaseUrl = '/api/core/water-meters-sizes';
  const waterMeterProtocolsBaseUrl = '/api/core/water-meter-communcation-protocol';
  const waterMeterManufacturerBaseUrl = '/api/core/water-meter-manufacturer';

  // Fetch all configuration data on component mount
  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        setIsLoading(true);
        const [sizesRes, protocolsRes, manufacturersRes] = await Promise.all([
          makeRequest2(waterMeterSizeBaseUrl, 'GET'),
          makeRequest2(waterMeterProtocolsBaseUrl, 'GET'),
          makeRequest2(waterMeterManufacturerBaseUrl, 'GET')
        ]);

        if (sizesRes.success) setMeterSizes(sizesRes.data || []);
        if (protocolsRes.success) setProtocols(protocolsRes.data || []);
        if (manufacturersRes.success) setManufacturers(manufacturersRes.data || []);
      } catch (error) {
        console.error('Error fetching configurations:', error);
        toastify('Error loading configuration data', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfigs();
  }, []);

  const handleSubmit = async () => {
    try {
      // Form validation
      if (!serialNumber.trim()) {
        toastify("Serial Number is required", "error");
        return;
      }
      if (!manufacturer) {
        toastify("Manufacturer is required", "error");
        return;
      }
      if (!protocol) {
        toastify("Protocol is required", "error");
        return;
      }
      if (!size) {
        toastify("Size is required", "error");
        return;
      }

      setIsSubmitting(true);

      const waterMeterData = {
        serialNumber: serialNumber.trim(),
        manufacturer,
        protocol,
        size,
        status: 'closed',
        initialReading: 0.00,
      };

      const response = await makeRequest2(
        waterMeterBaseUrl,
        "POST",
        waterMeterData
      );

      if (response.success) {
        toastify("Water meter added successfully", "success");
        handleClear();
        navigate("/core/water_meter_management/meters");
      } else {
        toastify(response.error || "Failed to add water meter", "error");
      }
    } catch (error) {
      console.error("Error adding water meter:", error);
      toastify(
        "An unexpected error occurred while adding the water meter",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClear = () => {
    setSerialNumber("");
    setManufacturer("");
    setProtocol("");
    setSize("");
  };

  if (isLoading) {
    return (
      <Layout>
        <div className="text-center p-5">
          Loading configuration data...
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/core/water_meter_management/meters">Water Meters</Link>
                </li>
                <li className="breadcrumb-item">Add New Water Meter</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Water Meter</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="form-label">
                      Serial Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <InputText
                      className="form-control"
                      value={serialNumber}
                      onChange={(e) => setSerialNumber(e.target.value)}
                      placeholder="Enter serial number"
                      disabled={isSubmitting}
                    />
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">
                      Manufacturer<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={manufacturer}
                      onChange={(e) => setManufacturer(e.target.value)}
                      disabled={isSubmitting}
                    >
                      <option value="">Select Manufacturer</option>
                      {manufacturers.map((mfr) => (
                        <option key={mfr._id} value={mfr._id}>
                          {mfr.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">
                      Protocol<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={protocol}
                      onChange={(e) => setProtocol(e.target.value)}
                      disabled={isSubmitting}
                    >
                      <option value="">Select Protocol</option>
                      {protocols.map((proto) => (
                        <option key={proto._id} value={proto._id}>
                          {proto.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">
                      Size<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                      disabled={isSubmitting}
                    >
                      <option value="">Select Size</option>
                      {meterSizes.map((s) => (
                        <option key={s._id} value={s._id}>
                          {s.code}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClear}
                  disabled={isSubmitting}
                >
                  Clear
                </Button>
                &nbsp;
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddWaterMeter;