import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { waterMeterBaseUrl } from '../../../utils/urls';
import Layout from '../component/layout';

function Meters() {
  const navigate = useNavigate();
  const [meters, setMeters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMeters, setFilteredMeters] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Config states
  const [manufacturers, setManufacturers] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const [meterSizes, setMeterSizes] = useState([]);

  //state for file upload dialog
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  // Form data state
  const [formData, setFormData] = useState({
    serialNumber: '',
    manufacturer: '',
    protocol: '',
    size: '',
    status: 'active',
    value: '',
  });

  // Base URLs for configs
  const waterMeterSizeBaseUrl = '/api/core/water-meters-sizes';
  const waterMeterProtocolsBaseUrl = '/api/core/water-meter-communcation-protocol';
  const waterMeterManufacturerBaseUrl = '/api/core/water-meter-manufacturer';

  // Fetch all configuration data
  const fetchConfigs = async () => {
    try {
      const [sizesRes, protocolsRes, manufacturersRes] = await Promise.all([
        makeRequest2(waterMeterSizeBaseUrl, 'GET'),
        makeRequest2(waterMeterProtocolsBaseUrl, 'GET'),
        makeRequest2(waterMeterManufacturerBaseUrl, 'GET')
      ]);

      if (sizesRes.success) setMeterSizes(sizesRes.data || []);
      if (protocolsRes.success) setProtocols(protocolsRes.data || []);
      if (manufacturersRes.success) setManufacturers(manufacturersRes.data || []);
    } catch (error) {
      console.error('Error fetching configurations:', error);
      toastify('Error loading configuration data', 'error');
    }
  };

  const fetchMeters = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2(waterMeterBaseUrl, 'GET');
      if (response.success) {
        setMeters(response.data || []);
        setFilteredMeters(response.data || []);
      } else {
        toastify(response.error || 'Failed to fetch meters', 'error');
      }
    } catch (error) {
      console.error('Error fetching meters:', error);
      toastify('An error occurred while fetching meters', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Promise.all([fetchMeters(), fetchConfigs()]);
  }, []);

  useEffect(() => {
    const filtered = meters.filter(meter =>
      meter.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      manufacturers.find(m => m._id === meter.manufacturer)?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMeters(filtered);
  }, [searchTerm, meters, manufacturers]);

  const getManufacturerName = (manufacturerId) => {
    const manufacturer = manufacturers.find(m => m._id === manufacturerId);
    return manufacturer ? manufacturer.name : 'null';
  };

  const getProtocolName = (protocolId) => {
    const protocol = protocols.find(p => p._id === protocolId);
    return protocol ? protocol.name : 'null';
  };

  const getSizeName = (sizeId) => {
    const size = meterSizes.find(s => s._id === sizeId);
    return size ? size.code : 'null';
  };

  const manufacturerTemplate = (rowData) => getManufacturerName(rowData.manufacturer);
  const protocolTemplate = (rowData) => getProtocolName(rowData.protocol);
  const sizeTemplate = (rowData) => getSizeName(rowData.size);

  const actionsTemplate = (rowData) => (
    <div>
      <Button
        icon="ti ti-edit"
        className="p-button-rounded p-button-info mr-2"
        onClick={() => handleModalOpen(rowData)}
        tooltip="Edit"
      />
      {/* <Button
        icon="ti ti-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => handleDelete(rowData._id)}
        tooltip="Delete"
      /> */}
    </div>
  );

  const statusTemplate = (rowData) => {
    const statusColors = {
      'open': 'success',
      'closed': 'danger',
      'maintenance': 'warning',
      'faulty': 'danger'
    };

    return (
      <span className={`badge bg-${statusColors[rowData.status] || 'secondary'}`}>
        {rowData.status}
      </span>
    );
  };

  const handleFileSelect = (event) => {
    setUploadedFile(event.files[0]);
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (!uploadedFile) {
      toastify('Please select a file first', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadedFile);

    try {
      const response = await makeRequest2(`${waterMeterBaseUrl}/import`, 'POST', formData, true);
      if (response.success) {
        toastify('Meters imported successfully', 'success');
        fetchMeters();
        setShowImportDialog(false);
      } else {
        toastify(response.error || 'Failed to import meters', 'error');
      }
    } catch (error) {
      console.error('Error importing meters:', error);
      toastify('An error occurred while importing meters', 'error');
    }
  };

  const handleModalOpen = (meter) => {
    if (meter) {
      setSelectedMeter(meter);
      setFormData({
        serialNumber: meter.serialNumber || '',
        manufacturer: meter.manufacturer || '',
        protocol: meter.protocol || '',
        size: meter.size || '',
        status: meter.status || 'active',
        initialReading: meter.initialReading || '',
      });
    }
    setShowModal(true);
  };

  const handleSubmit = async () => {
    try {
      if (!formData.serialNumber.trim()) {
        toastify("Serial Number is required", "error");
        return;
      }
      if (!formData.manufacturer) {
        toastify("Manufacturer is required", "error");
        return;
      }
      if (!formData.protocol) {
        toastify("Protocol is required", "error");
        return;
      }
      if (!formData.size) {
        toastify("Size is required", "error");
        return;
      }
      if (!formData.initialReading || isNaN(formData.initialReading) || formData.initialReading < 0) {
        toastify("Valid initial reading is required", "error");
        return;
      }

      setIsSubmitting(true);

      const response = await makeRequest2(
        `${waterMeterBaseUrl}/${selectedMeter._id}`,
        'PUT',
        formData
      );

      if (response.success) {
        toastify('Meter updated successfully', 'success');
        fetchMeters();
        setShowModal(false);
      } else {
        toastify(response.error || 'Failed to update meter', 'error');
      }
    } catch (error) {
      console.error('Error updating meter:', error);
      toastify('An error occurred while updating the meter', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    confirmDialog({
      message: 'Are you sure you want to delete this meter?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        try {
          const response = await makeRequest2(`${waterMeterBaseUrl}/${id}`, 'DELETE');
          if (response.success) {
            toastify('Meter deleted successfully', 'success');
            fetchMeters();
          } else {
            toastify(response.error || 'Failed to delete meter', 'error');
          }
        } catch (error) {
          toastify('An error occurred while deleting the meter', 'error');
        }
      },
    });
  };

  // Import Dialog Component
  const ImportDialog = () => (
    <Dialog
      header="Import Meters"
      visible={showImportDialog}
      onHide={() => setShowImportDialog(false)}
      style={{ width: '50vw' }}
    >
      <div className="p-fluid">
        <div className="field mb-4">
          <h5>Upload CSV or Excel File</h5>
          <FileUpload
            mode="basic"
            accept=".csv,.xlsx,.xls"
            maxFileSize={1000000}
            chooseLabel="Choose File"
            className="mb-3"
            onSelect={handleFileSelect}
          />
          <small className="text-muted block">
            Allowed file types: .csv, .xlsx, .xls (Max size: 1MB)
          </small>
        </div>
        <div className="text-end mt-4">
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => setShowImportDialog(false)}
            className="p-button-text mr-2"
          />
          <Button
            label="Import"
            icon="pi pi-check"
            onClick={handleFileUpload}
            disabled={!uploadedFile}
          />
        </div>
      </div>
    </Dialog>
  );

  return (
    <Layout>
      <ConfirmDialog />
      <div className="page-header">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/core/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">Water Meter Management
          </li>
          <li className="breadcrumb-item active">Meters</li>
        </ul>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-3">
                  <div className="form-search">
                    <i className="ti ti-search"></i>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by serial no or manufacturer"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div style={{ float: 'right' }}>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate('/core/water_meter_management/add_new_meter')}
                    >
                      Add New Meter
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowImportDialog(true)}
                    >
                      Import Meter(s)
                    </button>
                  </div>
                </div>
              </div>

              <DataTable
                value={filteredMeters}
                loading={loading}
                paginator
                rows={10}
                stripedRows
                emptyMessage="No water meters found."
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column field="serialNumber" header="Serial Number" sortable />
                <Column header="Manufacturer" body={manufacturerTemplate} sortable sortField="manufacturer" />
                <Column header="Protocol" body={protocolTemplate} sortable sortField="protocol" />
                <Column header="Size" body={sizeTemplate} sortable sortField="size" />
                <Column
                  field="isInstalled"
                  sortable
                  header="Installation Status"
                  body={(rowData) => rowData.isInstalled ? 'Installed' : 'Not Installed'}
                ></Column>
                <Column field="status" sortable header="Status" body={statusTemplate}></Column>
                <Column field="initialReading" header="Initial V (m³)" sortable />
                <Column header="Actions" body={actionsTemplate} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header="Edit Meter"
        visible={showModal}
        onHide={() => setShowModal(false)}
        style={{ width: '50vw' }}
      >
        <div className="p-fluid">
          <div className="field mb-3">
            <label className="form-label">
              Serial Number<span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              value={formData.serialNumber}
              onChange={(e) => setFormData({ ...formData, serialNumber: e.target.value })}
              className="form-control"
              disabled={isSubmitting}
            />
          </div>

          <div className="field mb-3">
            <label className="form-label">
              Manufacturer<span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="form-control"
              value={formData.manufacturer}
              onChange={(e) => setFormData({ ...formData, manufacturer: e.target.value })}
              disabled={isSubmitting}
            >
              <option value="">Select Manufacturer</option>
              {manufacturers.map((mfr) => (
                <option key={mfr._id} value={mfr._id}>
                  {mfr.name}
                </option>
              ))}
            </select>
          </div>

          <div className="field mb-3">
            <label className="form-label">
              Protocol<span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="form-control"
              value={formData.protocol}
              onChange={(e) => setFormData({ ...formData, protocol: e.target.value })}
              disabled={isSubmitting}
            >
              <option value="">Select Protocol</option>
              {protocols.map((proto) => (
                <option key={proto._id} value={proto._id}>
                  {proto.name}
                </option>
              ))}
            </select>
          </div>

          <div className="field mb-3">
            <label className="form-label">
              Size<span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="form-control"
              value={formData.size}
              onChange={(e) => setFormData({ ...formData, size: e.target.value })}
              disabled={isSubmitting}
            >
              <option value="">Select Size</option>
              {meterSizes.map((s) => (
                <option key={s._id} value={s._id}>
                  {s.code}
                </option>
              ))}
            </select>
          </div>

          <div className="field mb-3">
            <label className="form-label">Status</label>
            <select
              className="form-control"
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              disabled={isSubmitting}
            >
              <option value="open">Open</option>
              <option value="closed">Closed</option>
              <option value="maintenance">Maintenance</option>
            </select>
          </div>

          <div className="field mb-3">
            <label className="form-label">
              Initial Reading (m³)<span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              className="form-control"
              initialReading={formData.initialReading}
              onChange={(e) => setFormData({ ...formData, initialReading: e.target.value })}
              disabled={isSubmitting}
              type="number"
              min="0"
              step="0.01"
            />
          </div>
        </div>
        <div className="text-end mt-4">
          <Button
            label={isSubmitting ? "Saving..." : "Save"}
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="p-button-primary"
          />
        </div>
      </Dialog>
      <ImportDialog />
    </Layout>
  );
}

export default Meters;