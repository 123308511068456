import {
    createBrowserRouter
} from "react-router-dom";
// // import { BrowserRouter, Routes, Route } from "react-router-dom";

// PAGES    


import Error404Page from '../components/error/Error404Page'



import waterMeterManagementRoutes from "../components/core/water_management/routes";
import authenticationRoutes from "../components/authentication/routes";
import settingRoutes from "../components/core/settings/routes";
import messageRoutes from "../components/core/messages/routes";
import unitRoutes from "../components/core/unit_management/routes";
import facilityRoutes from "../components/core/facility_management/routes";
import companyRoutes from "../components/core/company_management/routes";
import dashboardRoutes from "../components/core/dashboard/routes";


export const router = createBrowserRouter([
    ...authenticationRoutes,
    ...dashboardRoutes,
    ...companyRoutes,
    ...facilityRoutes,
    ...unitRoutes,
    ...waterMeterManagementRoutes,
    ...messageRoutes,
    ...settingRoutes,
    {
        path: "*",
        element: <Error404Page />,
    },
]);