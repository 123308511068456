import Facilities  from "./facilities"
import AddFacility from "./add_facility"
import ViewFacility from "./view_facility"
const facilityRoutes = [
    {
        path: "/core/facilities",
        element: <Facilities />,
    },
    {
        path: "/core/facilities/add_facility/:companyId",
        element: <AddFacility />

    },
    {
        path: "/core/facilities/view_facility/:facilityId",
        element: <ViewFacility />

    }
]
export default facilityRoutes