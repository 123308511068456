import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import Layout from '../component/layout';
import { makeRequest2, makeRequest3 } from '../../../utils/makeRequest';
import { waterMeterBaseUrl, get_units_per_facility } from '../../../utils/urls';
import { toastify } from '../../../utils/toast';

const PostDeliveryReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const deliveryData = location.state || {};

  const [activeTab, setActiveTab] = useState('deliveryNote');
  const [loading, setLoading] = useState(false);
  const [facilityMeters, setFacilityMeters] = useState([]);
  const [facilityUnits, setFacilityUnits] = useState([]);
  const [concentrators, setConcentrators] = useState([]);

  // Track completion status of each tab
  const [tabStatus, setTabStatus] = useState({
    deliveryNote: false,
    meterAssignment: false,
    concentratorUpdate: false,
  });

  // Form states
  const [deliveryForm, setDeliveryForm] = useState({
    receivedBy: '',
    comments: '',
    deliveryNote: null,
  });
  const [meterAssignments, setMeterAssignments] = useState({});
  const [concentratorLocations, setConcentratorLocations] = useState({});

  // Fetch saved delivery details and prepopulate the form and assignments
  const fetchDeliveryDetails = async () => {
    try {
      const response = await makeRequest2(
        `/api/core/water-meters-delivery/details/${deliveryData.deliveryId}`,
        'GET'
      );
      if (response.success) {
        const fetchedDelivery = response.data.delivery;
        // Prepopulate the delivery note fields
        setDeliveryForm((prev) => ({
          ...prev,
          receivedBy: fetchedDelivery.receivedBy || '',
          comments: fetchedDelivery.postNotes || '',
        }));

        // Prepopulate meter assignments if a unit was already assigned
        const preMeterAssignments = {};
        if (Array.isArray(fetchedDelivery.meters)) {
          fetchedDelivery.meters.forEach((meter) => {
            // Assume the backend attaches "meterDetails" with UnitInfo when a unit is assigned
            if (
              meter.meterDetails &&
              meter.meterDetails.UnitInfo &&
              meter.meterDetails.UnitInfo._id
            ) {
              preMeterAssignments[meter.meterId] = meter.meterDetails.UnitInfo._id;
            }
          });
        }
        setMeterAssignments(preMeterAssignments);

        // Prepopulate concentrator location details if available
        const preConcentratorLocations = {};
        if (Array.isArray(fetchedDelivery.concentrators)) {
          fetchedDelivery.concentrators.forEach((conc) => {
            if (conc.concentratorDetails && conc.concentratorDetails.location) {
              preConcentratorLocations[conc.concentratorId] = {
                location: conc.concentratorDetails.location.city || '',
                latitude: conc.concentratorDetails.location.latitude || '',
                longitude: conc.concentratorDetails.location.longitude || '',
              };
            }
          });
        }
        setConcentratorLocations(preConcentratorLocations);

        // Optionally mark tabs as complete if data exists
        setTabStatus({
          deliveryNote: !!fetchedDelivery.receivedBy,
          meterAssignment: Object.keys(preMeterAssignments).length > 0,
          concentratorUpdate: Object.keys(preConcentratorLocations).length > 0,
        });
      } else {
        toastify('Failed to load delivery details', 'error');
      }
    } catch (error) {
      toastify('Failed to load delivery details', 'error');
      console.error(error);
    }
  };

  // Fetch facility meters, concentrators, and units
  const getFacilityMeters = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2(waterMeterBaseUrl, 'GET');
      if (response.success) {
        // Filter meters based on the delivery's saved meter IDs
        const deliveryMeterIds = (deliveryData.meters || []).map((m) => m.meterId);
        const relevantMeters = response.data.filter((meter) =>
          deliveryMeterIds.includes(meter._id)
        );
        // Enhance the meter objects with the serial number from delivery data
        const enhancedMeters = relevantMeters.map((meter) => {
          const deliveryMeter = (deliveryData.meters || []).find(
            (m) => m.meterId === meter._id
          );
          return {
            ...meter,
            deliverySerialNumber: deliveryMeter?.serialNumber,
          };
        });
        setFacilityMeters(enhancedMeters);
      }
    } catch (error) {
      toastify('Error fetching meters', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getFacilityConcetrators = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2('/api/core/concentrators', 'GET');
      if (response.success) {
        const deliveryConcentratorIds = (deliveryData.concentrators || []).map(
          (c) => c.concentratorId
        );
        const relevantConcentrators = response.data.filter((concentrator) =>
          deliveryConcentratorIds.includes(concentrator._id)
        );
        const enhancedConcentrators = relevantConcentrators.map((concentrator) => {
          const deliveryConcentrator = (deliveryData.concentrators || []).find(
            (c) => c.concentratorId === concentrator._id
          );
          return {
            ...concentrator,
            deliverySerialNumber: deliveryConcentrator?.serialNumber,
          };
        });
        setConcentrators(enhancedConcentrators);
      }
    } catch (error) {
      toastify('Error fetching concentrators', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getFacilityUnits = async () => {
    try {
      const facilityId = deliveryData.facilityId?._id || deliveryData.facilityId;
      if (!facilityId) {
        toastify('Missing facility ID', 'error');
        return;
      }
      const response = await makeRequest2(
        `${get_units_per_facility}/${facilityId}`,
        'GET'
      );
      if (response.success) {
        setFacilityUnits(response.data);
      } else {
        toastify('Failed to fetch units', 'error');
      }
    } catch (error) {
      toastify('Error fetching units', 'error');
    }
  };

  // Run all initialization: fetch facility data and the saved delivery details
  useEffect(() => {
    if (!deliveryData.deliveryId || !deliveryData.facilityId) {
      navigate('/core/water_meter_management/deliveries');
      return;
    }
    const fetchAllData = async () => {
      await Promise.all([
        getFacilityMeters(),
        getFacilityConcetrators(),
        getFacilityUnits(),
        fetchDeliveryDetails(),
      ]);
    };
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryData.facilityId, deliveryData.deliveryId]);

  // Event handlers
  const handleDeliveryFormChange = (e) => {
    const { name, value } = e.target;
    setDeliveryForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('Selected file:', file);
    if (file) {
      if (
        file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        console.log('Valid file selected:', {
          name: file.name,
          type: file.type,
          size: file.size,
        });
        setDeliveryForm((prev) => ({
          ...prev,
          deliveryNote: file,
        }));
      } else {
        toastify('Please upload a PDF or Word document', 'error');
        e.target.value = '';
      }
    }
  };

  const handleUnitAssignment = (meterId, unitId) => {
    setMeterAssignments((prev) => ({
      ...prev,
      [meterId]: unitId,
    }));
  };

  const handleConcentratorLocationUpdate = (concentratorId, field, value) => {
    setConcentratorLocations((prev) => ({
      ...prev,
      [concentratorId]: {
        ...prev[concentratorId],
        [field]: value,
      },
    }));
  };

  // Save handlers for each tab
  const saveDeliveryNote = async () => {
    try {
      setLoading(true);
      if (!deliveryForm.receivedBy) {
        toastify('Received By is required', 'error');
        return;
      }
      const formData = new FormData();
      if (deliveryForm.deliveryNote instanceof File) {
        formData.append('signedDocument', deliveryForm.deliveryNote);
      }
      formData.append('receivedBy', deliveryForm.receivedBy);
      formData.append('postNotes', deliveryForm.comments || '');
      const uploadResponse = await makeRequest3(
        `/api/core/uploads/upload-document/${deliveryData.deliveryId}`,
        'POST',
        formData
      );
      if (uploadResponse.success) {
        toastify('Delivery note uploaded successfully', 'success');
        setTabStatus((prev) => ({ ...prev, deliveryNote: true }));
      } else {
        toastify('Failed to upload delivery note', 'error');
      }
    } catch (error) {
      toastify('Failed to save delivery note', 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveMeterAssignments = async () => {
    try {
      setLoading(true);
      const meterUpdates = Object.entries(meterAssignments).map(
        ([meterId, unitId]) =>
          makeRequest2(`${waterMeterBaseUrl}/${meterId}`, 'PUT', {
            unitId,
            status: 'inactive',
            isInstalled: 'true',
          })
      );
      await Promise.all(meterUpdates);
      setTabStatus((prev) => ({ ...prev, meterAssignment: true }));
      toastify('Meter assignments saved successfully', 'success');
    } catch (error) {
      toastify('Failed to save meter assignments', 'error');
    } finally {
      setLoading(false);
    }
  };

  const saveConcentratorLocations = async () => {
    try {
      setLoading(true);
      const concentratorUpdates = Object.entries(concentratorLocations).map(
        ([concentratorId, locationData]) =>
          makeRequest2(`/api/core/concentrators/${concentratorId}`, 'PUT', {
            location: {
              city: locationData.location,
              latitude: Number(locationData.latitude),
              longitude: Number(locationData.longitude),
            },
            status: 'Online',
            isInstalled: 'true',
          })
      );
      await Promise.all(concentratorUpdates);
      setTabStatus((prev) => ({ ...prev, concentratorUpdate: true }));
      toastify('Concentrator locations updated successfully', 'success');
    } catch (error) {
      toastify('Failed to update concentrator locations', 'error');
    } finally {
      setLoading(false);
    }
  };

  const completeDelivery = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2(
        `/api/core/water-meters-delivery/${deliveryData.deliveryId}`,
        'PUT',
        { status: 'completed' }
      );
      if (response.success) {
        toastify('Delivery completed successfully', 'success');
        navigate('/core/water_meter_management/deliveries');
      }
    } catch (error) {
      toastify('Failed to complete delivery', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Render tab content
  const renderDeliveryNoteTab = () => (
    <div className="card">
      <div className="card-header">
        <h5>Delivery Details</h5>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-6">
            <p>
              <strong>Delivery Number:</strong> {deliveryData.deliveryNumber}
            </p>
            <p>
              <strong>Facility:</strong> {deliveryData.facilityName}
            </p>
            <p>
              <strong>Delivered By:</strong> {deliveryData.deliveredBy}
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Delivery Date:</strong>{' '}
              {new Date(deliveryData.deliveryDate).toLocaleDateString()}
            </p>
            <p>
              <strong>Initial Notes:</strong> {deliveryData.notes || 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Upload Delivery Note<span className="text-danger">*</span>
              </label>
              <input
                type="file"
                className="form-control mt-2"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
              />
            </div>
            <div className="form-group mt-3">
              <label>
                Received By<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="receivedBy"
                className="form-control mt-2"
                value={deliveryForm.receivedBy}
                onChange={handleDeliveryFormChange}
              />
            </div>
            <div className="form-group mt-3">
              <label>Comments</label>
              <textarea
                name="comments"
                className="form-control mt-2"
                rows="4"
                value={deliveryForm.comments}
                onChange={handleDeliveryFormChange}
              />
            </div>
            <div className="mt-3">
              <Button
                label="Save Delivery Note"
                onClick={saveDeliveryNote}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderMeterAssignmentTab = () => (
    <div className="card">
      <div className="card-header">
        <h5>Assign Meters to Units</h5>
      </div>
      <div className="card-body">
        <DataTable
          value={facilityMeters}
          loading={loading}
          paginator
          rows={10}
          stripedRows
        >
          <Column
            field="serialNumber"
            header="Meter Serial Number"
            sortable
          />
          <Column
            field="deliverySerialNumber"
            header="Delivery Serial Number"
            sortable
          />
          <Column
            header="Unit Assignment"
            body={(rowData) => (
              <select
                className="form-control"
                value={meterAssignments[rowData._id] || ''}
                onChange={(e) =>
                  handleUnitAssignment(rowData._id, e.target.value)
                }
              >
                <option value="">-- Select Unit --</option>
                {facilityUnits.map((unit) => (
                  <option key={unit._id} value={unit._id}>
                    {unit.name}
                  </option>
                ))}
              </select>
            )}
          />
        </DataTable>
        <div className="mt-3">
          <Button
            label="Save Assignments"
            onClick={saveMeterAssignments}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );

  const renderConcentratorTab = () => (
    <div className="card">
      <div className="card-header">
        <h5>Update Concentrator Locations</h5>
      </div>
      <div className="card-body">
        <DataTable
          value={concentrators}
          loading={loading}
          paginator
          rows={10}
          stripedRows
        >
          <Column
            field="serialNumber"
            header="Serial Number"
            sortable
          />
          <Column
            header="City"
            body={(rowData) => (
              <input
                type="text"
                className="form-control"
                placeholder="Enter city name"
                value={concentratorLocations[rowData._id]?.location || ''}
                onChange={(e) =>
                  handleConcentratorLocationUpdate(
                    rowData._id,
                    'location',
                    e.target.value
                  )
                }
              />
            )}
          />
          <Column
            header="Latitude"
            body={(rowData) => (
              <input
                type="number"
                step="0.000001"
                className="form-control"
                placeholder="-90 to 90"
                min="-90"
                max="90"
                value={concentratorLocations[rowData._id]?.latitude || ''}
                onChange={(e) =>
                  handleConcentratorLocationUpdate(
                    rowData._id,
                    'latitude',
                    e.target.value
                  )
                }
              />
            )}
          />
          <Column
            header="Longitude"
            body={(rowData) => (
              <input
                type="number"
                step="0.000001"
                className="form-control"
                placeholder="-180 to 180"
                min="-180"
                max="180"
                value={concentratorLocations[rowData._id]?.longitude || ''}
                onChange={(e) =>
                  handleConcentratorLocationUpdate(
                    rowData._id,
                    'longitude',
                    e.target.value
                  )
                }
              />
            )}
          />
        </DataTable>
        <div className="mt-3">
          <Button
            label="Save Locations"
            onClick={saveConcentratorLocations}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/core/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/core/water_meter_management/deliveries">Deliveries</Link>
                </li>
                <li className="breadcrumb-item">
                  <span>Post-Delivery Report</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Post-Delivery Report</h5>
            </div>
            <div className="card-body">
              <ul className="nav nav-tabs mb-3" role="tablist">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'deliveryNote' ? 'active' : ''}`}
                    onClick={() => setActiveTab('deliveryNote')}
                    role="tab"
                    aria-selected={activeTab === 'deliveryNote'}
                    aria-controls="deliveryNotePanel"
                  >
                    <i className="ti ti-clipboard me-2"></i>
                    Delivery Note
                    {tabStatus.deliveryNote && (
                      <i className="ti ti-check text-success ms-2"></i>
                    )}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'meterAssignment' ? 'active' : ''}`}
                    onClick={() => setActiveTab('meterAssignment')}
                    role="tab"
                    aria-selected={activeTab === 'meterAssignment'}
                    aria-controls="meterAssignmentPanel"
                  >
                    <i className="ti ti-home-link me-2"></i>
                    Meter Assignment
                    {tabStatus.meterAssignment && (
                      <i className="ti ti-check text-success ms-2"></i>
                    )}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'concentratorUpdate' ? 'active' : ''}`}
                    onClick={() => setActiveTab('concentratorUpdate')}
                    role="tab"
                    aria-selected={activeTab === 'concentratorUpdate'}
                    aria-controls="concentratorUpdatePanel"
                  >
                    <i className="ti ti-device-desktop me-2"></i>
                    Concentrator Update
                    {tabStatus.concentratorUpdate && (
                      <i className="ti ti-check text-success ms-2"></i>
                    )}
                  </button>
                </li>
              </ul>

              {/* Tab Panels */}
              <div className="tab-content">
                <div
                  role="tabpanel"
                  id="deliveryNotePanel"
                  aria-labelledby="deliveryNoteTab"
                  hidden={activeTab !== 'deliveryNote'}
                >
                  {activeTab === 'deliveryNote' && renderDeliveryNoteTab()}
                </div>
                <div
                  role="tabpanel"
                  id="meterAssignmentPanel"
                  aria-labelledby="meterAssignmentTab"
                  hidden={activeTab !== 'meterAssignment'}
                >
                  {activeTab === 'meterAssignment' && renderMeterAssignmentTab()}
                </div>
                <div
                  role="tabpanel"
                  id="concentratorUpdatePanel"
                  aria-labelledby="concentratorUpdateTab"
                  hidden={activeTab !== 'concentratorUpdate'}
                >
                  {activeTab === 'concentratorUpdate' && renderConcentratorTab()}
                </div>
              </div>

              {Object.values(tabStatus).every((status) => status) && (
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <Button
                      label="Complete Delivery"
                      icon="ti ti-check"
                      className="p-button-success"
                      onClick={completeDelivery}
                      loading={loading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostDeliveryReport;
