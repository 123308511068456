import ImportUnit from "./import_unit"
import ViewUnit from "./view_unit"
const unitRoutes = [
    {
        path: '/core/units/import_units/:facilityId',
        element: <ImportUnit />
    },
    {
        path: "/core/units/view_unit/:unitId/:facilityId",
        element: <ViewUnit />

    }
]
export default unitRoutes