import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { concentratorBaseUrl } from '../../../utils/urls';
import Layout from '../component/layout';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

function Concentrators() {
  const navigate = useNavigate();
  const [concentrators, setConcentrators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredConcentrators, setFilteredConcentrators] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedConcentrator, setSelectedConcentrator] = useState(null);
  const [formData, setFormData] = useState({
    serialNumber: '',
    manufacturer: '',
    range: 0,
    status: 'Offline',
    isInstalled: false,
    isFaulty: false,
    inStock: false,
  });

  const fetchConcentrators = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2(concentratorBaseUrl, 'GET');
      
      if (response.success) {
        setConcentrators(response.data || []);
        setFilteredConcentrators(response.data || []);
      } else {
        toastify(response.error || 'Failed to fetch concentrators', 'error');
      }
    } catch (error) {
      console.error('Error fetching concentrators:', error);
      toastify('An error occurred while fetching concentrators', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConcentrators();
  }, []);

  useEffect(() => {
    const filtered = concentrators.filter(concentrator => 
      concentrator.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      concentrator.manufacturer?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      concentrator.status?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredConcentrators(filtered);
  }, [searchTerm, concentrators]);

  const statusTemplate = (rowData) => {
    const statusColors = {
      'Online': 'success',
      'Offline': 'danger',
      'Maintenance': 'warning'
    };
    
    return (
      <span className={`badge bg-${statusColors[rowData.status] || 'secondary'}`}>
        {rowData.status}
      </span>
    );
  };

  const handleModalOpen = (concentrator) => {
    if (concentrator) {
      setSelectedConcentrator(concentrator);
      setFormData({
        serialNumber: concentrator.serialNumber || '',
        manufacturer: concentrator.manufacturer || '',
        range: concentrator.range || 0,
        status: concentrator.status || 'Offline',
        isInstalled: concentrator.isInstalled || false,
        isFaulty: concentrator.isFaulty || false,
        inStock: concentrator.inStock || false,
      });
    } else {
      setSelectedConcentrator(null);
      setFormData({ serialNumber: '', manufacturer: '', range: 0, status: 'Offline', isInstalled: false, isFaulty: false, inStock: false });
    }
    setShowModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedConcentrator) {
        await makeRequest2(`${concentratorBaseUrl}/${selectedConcentrator._id}`, 'PUT', formData);
        toastify('Concentrator updated successfully', 'success');
      }
      fetchConcentrators();
      setShowModal(false);
    } catch (error) {
      toastify('An error occurred while saving the concentrator', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    confirmDialog({
      message: 'Are you sure you want to delete this concentrator?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        try {
          await makeRequest2(`${concentratorBaseUrl}/${id}`, 'DELETE');
          toastify('Concentrator deleted successfully', 'success');
          fetchConcentrators();
        } catch (error) {
          toastify('An error occurred while deleting the concentrator', 'error');
        }
      },
    });
  };

  const actionsTemplate = (rowData) => (
    <div>
      <Button
        icon="ti ti-edit"
        className="p-button-rounded p-button-info mr-2"
        onClick={() => handleModalOpen(rowData)}
        tooltip="Edit"
      />
      <Button
        icon="ti ti-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => handleDelete(rowData._id)}
        tooltip="Delete"
      />
    </div>
  );

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => {}} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => {}} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => {}} data-pr-tooltip="PDF" />
    </div>
  );

  return (
    <Layout>
      <ConfirmDialog />
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Water Meter Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Concentrators</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-3">
                  <div className="form-search">
                    <i className="ti ti-search"></i>
                    <input 
                      type="search" 
                      className="form-control" 
                      placeholder="Search Here" 
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div style={{ float: 'right' }}>
                    <button 
                      className="btn btn-primary" 
                      onClick={() => navigate('/core/water_meter_management/add_new_concentrator')}
                    >
                      Add New Concentrator
                    </button>
                  </div>
                </div>
              </div>
              <div className="dt-responsive table-responsive mt-3">
                <DataTable 
                  value={filteredConcentrators} 
                  emptyMessage="No concentrators found."
                  sortMode="multiple" 
                  paginator 
                  rows={10}
                  stripedRows
                  tableStyle={{ minWidth: '50rem' }}
                  loading={loading}
                  header={header}
                >
                  <Column field="serialNumber" sortable header="Serial Number"></Column>
                  <Column field="manufacturer" sortable header="Manufacturer"></Column>
                  <Column field="range" sortable header="Range"></Column>
                  <Column field="status" sortable header="Status" body={statusTemplate}></Column>
                  <Column 
                    field="isInstalled" 
                    sortable 
                    header="Installation Status" 
                    body={(rowData) => rowData.isInstalled ? 'Installed' : 'Not Installed'}
                  ></Column>
                  <Column 
                    field="inStock" 
                    sortable 
                    header="Stock Status" 
                    body={(rowData) => rowData.inStock ? 'In Stock' : 'Out of Stock'}
                  ></Column>
                  <Column header="Actions" body={actionsTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal show d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Concentrator</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">
                      Serial Number<span style={{ color: 'red' }}>*</span>
                    </label>
                    <InputText
                      className="form-control"
                      value={formData.serialNumber}
                      onChange={(e) => setFormData({ ...formData, serialNumber: e.target.value })}
                      placeholder="Enter serial number"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      Manufacturer<span style={{ color: 'red' }}>*</span>
                    </label>
                    <InputText
                      className="form-control"
                      value={formData.manufacturer}
                      onChange={(e) => setFormData({ ...formData, manufacturer: e.target.value })}
                      placeholder="Enter manufacturer"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      Range<span style={{ color: 'red' }}>*</span>
                    </label>
                    <InputNumber
                      className="form-control"
                      value={formData.range}
                      onValueChange={(e) => setFormData({ ...formData, range: e.value })}
                      placeholder="Enter range"
                      required
                      min={0}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Status</label>
                    <select
                      className="form-control"
                      value={formData.status}
                      onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                    >
                      <option value="Offline">Offline</option>
                      <option value="Online">Online</option>
                      <option value="Maintenance">Maintenance</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={formData.isInstalled}
                        onChange={(e) => setFormData({ ...formData, isInstalled: e.target.checked })}
                      />
                      <label className="form-check-label">Is Installed</label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={formData.isFaulty}
                        onChange={(e) => setFormData({ ...formData, isFaulty: e.target.checked })}
                      />
                      <label className="form-check-label">Is Faulty</label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={formData.inStock}
                        onChange={(e) => setFormData({ ...formData, inStock: e.target.checked })}
                      />
                      <label className="form-check-label">In Stock</label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Concentrators;