import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { AddressAutofill } from "@mapbox/search-js-react";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { toastify } from "../../../utils/toast";
import Layout from "../component/layout";
import { makeRequest2 } from "../../../utils/makeRequest";
import {
  getFacilityUnits,
  getFacilityURL,
  updateFacilityMeasurement,
  updateTotalCommonArea,
  updateLettableArea,
  updateFacilityInfo,
  addAsset,
  deleteAsset,
  addUnitUrl,
  addLRNumber,
  deleteLRNumber,
  addCombinedUnitURL,
  getFacilityCombinedUnits,
  deleteFacilityCombineUnits,
  confirmCombinedUnitsURL,
  settingsBaseRoute,
} from "../../../utils/urls";
import { unitTypes, areaMeasurements } from "../../../utils/data";
import { filterObjectsByAllProperties } from "../../../utils/filterSearch";

function ViewFacility() {
  const navigate = useNavigate();
  const { facilityId } = useParams();
  const [facility, setFacility] = useState({});
  const [units, setUnits] = useState([]);
  const [currentUnit, setCurrentUnits] = useState([]);
  const [combinedUnits, setCombinedUnits] = useState([]);
  const [currentClickedUnit, setCurrentClickedUnit] = useState({});
  const [addUnitDialog, setAddUnitDialogVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [floorUnit, setFloorUnit] = useState("");
  const [unitType, setUnitType] = useState("");
  const [lrNumber, setLrNumber] = useState("");
  const [grossArea, setGrossArea] = useState("");
  const [netLettableArea, setNetLettableArea] = useState("");
  const [newAssetDialog, setNewAssetDialog] = useState(false);
  const [defaultMeasurement, setDefaultMeasurement] = useState("");
  const [facilityCommonArea, setFacilityCommonArea] = useState(0);
  const [lettableArea, setLettableArea] = useState(0);
  const [unitLettableArea, setUnitLettableArea] = useState(0);
  const [LRNumber, setLRNumber] = useState("");
  const [facilityName, setFacilityName] = useState("");
  const [facilityLocation, setFacilityLocation] = useState("");
  const [assetName, setAssetName] = useState("");
  const [assets, setAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [combineUnits, setCombineUnits] = useState(false);
  const [combineUnitsArray, setCombineUnitsArray] = useState([]);
  const [noCombinedUnits, setNoCombinedUnits] = useState(0);
  const [combinedUnitName, setCombinedUnitName] = useState("");
  const [combinedDivision, setCombinedDivision] = useState("");
  const [combinedUnitType, setCombinedUnitType] = useState("");
  const [combinedFloorUnit, setCombinedFloorUnit] = useState("");
  const [combinedLettableFloorArea, setCombineLettableFloorArea] = useState("");
  const [combinedLRNumber, setCombinedLRNumber] = useState("");
  const [combinedGrossArea, setCombinedGrossArea] = useState("");
  const [combinedNetLettableArea, setCombinedNetLettableArea] = useState("");
  const [combinedReason, setCombinedReason] = useState("");
  const [selectedCombinedUnits, setSelectedCombinedUnits] = useState([]);
  const [confirmCombineUnits, setConfirmCombineUnits] = useState(false);
  const [currentConfirmUnit, setCurrentConfirmedUnit] = useState({});
  //      Payment details
  const [paymentShortCode, setPaymentShortCode] = useState("");
  const [paymentPasskey, setPaymentPasskey] = useState("");
  const [paymentAuthKey, setPaymentAuthKey] = useState("");
  const [paymentModule, setPaymentModule] = useState("");
  const [hasPaymentDetails, setHasPaymentDetails] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [existingPaymentDetails, setExistingPaymentDetails] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);

  const getFacility = async () => {
    try {
      const response = await makeRequest2(
        getFacilityURL + "/" + facilityId,
        "GET"
      );
      if (response.success) {
        setFacility(response.data.facility);

        if (
          response.data.facility.defaultMeasurement !== undefined ||
          response.data.facility.totalCommonArea !== undefined ||
          response.data.facility.totalLettableArea !== undefined
        ) {
          setDefaultMeasurement(
            JSON.parse(response.data.facility.defaultMeasurement)
          );
          setFacilityCommonArea(response.data.facility.totalCommonArea);
          setLettableArea(response.data.facility.totalLettableArea);
        }

        setAssets(response.data.facilityAssets);

        if (response.data.facility.divisionArray !== undefined) {
          setCurrentClickedUnit("All");
        }
      }
    } catch (err) {
      console.log(err);
      toastify(err.message, "error");
    }
  };
  const getUnits = async () => {
    try {
      try {
        const response = await makeRequest2(
          getFacilityUnits + "/" + facilityId,
          "GET"
        );

        if (response.success) {
          setUnits(response.data);
          let filter = units.filter((item) => {
            return item.division === currentClickedUnit.divisionName;
          });
          setCurrentUnits(filter);
        }
      } catch (err) {
        toastify("Server error.", "error");
      }
    } catch (err) {
      toastify("Server error.", "error");
    }
  };
  const getCombinedUnits = async () => {
    try {
      try {
        const response = await makeRequest2(
          getFacilityCombinedUnits + "/" + facilityId,
          "GET"
        );

        if (response.success) {
          setCombinedUnits(response.data);
        }
      } catch (err) {
        toastify("Server error.", "error");
      }
    } catch (err) {
      toastify("Server error.", "error");
    }
  };
  const HandleDefaultMeasurement = async () => {
    try {
      const response = await makeRequest2(
        updateFacilityMeasurement + "/" + facilityId,
        "POST",
        {
          defaultMeasurement,
        }
      );
      if (response.success) {
        toastify(response.data, "success");
        getFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleFacilityCommonAreaSubmit = async () => {
    try {
      const response = await makeRequest2(
        updateTotalCommonArea + "/" + facilityId,
        "POST",
        {
          totalCommonArea: facilityCommonArea,
        }
      );
      if (response.success) {
        toastify(response.data, "success");
        getFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleLettableAreaSubmit = async () => {
    try {
      const response = await makeRequest2(
        updateLettableArea + "/" + facilityId,
        "POST",
        {
          totalLettableArea: lettableArea,
        }
      );
      if (response.success) {
        toastify(response.data, "success");
        getFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleFacilityInfoSubmit = async () => {
    try {
      if (facilityName === "") {
        throw new Error("Facility Name is required.");
      }
      if (facilityLocation === "") {
        throw new Error("Facility Location is required.");
      }
      const response = await makeRequest2(
        updateFacilityInfo + "/" + facilityId,
        "POST",
        {
          name: facilityName,
          location: facilityLocation,
        }
      );

      if (response.success) {
        toastify(response.data, "success");
        getFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleAssetSubmit = async () => {
    try {
      if (assetName === "") {
        throw new Error("Asset Name is required.");
      }
      const response = await makeRequest2(addAsset + "/" + facilityId, "POST", {
        name: assetName,
      });
      if (response.success) {
        toastify(response.data, "success");
        getFacility();
        setNewAssetDialog(false);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleDeleteAsset = async (id) => {
    try {
      const response = await makeRequest2(deleteAsset + "/" + id, "GET");
      if (response.success) {
        toastify(response.data, "success");
        getFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleLRNumberSubmit = async () => {
    try {
      const response = await makeRequest2(
        addLRNumber + "/" + facilityId,
        "POST",
        {
          LRNumber,
        }
      );
      if (response.success) {
        toastify(response.data, "success");
        setLRNumber("");
        getFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleDeleteLRNumber = async (lrNumber) => {
    try {
      const response = await makeRequest2(
        deleteLRNumber + "/" + facilityId,
        "POST",
        {
          lrNumber,
        }
      );
      if (response.success) {
        toastify(response.data, "success");
        getFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const deleteAssetTemplate = (rowData) => {
    return (
      <Link
        to={"#"}
        className="avtar avtar-xs btn-link-secondary btn-pc-default"
        onClick={() => {
          HandleDeleteAsset(rowData._id);
        }}
      >
        <i className="ti ti-trash f-18" style={{ color: "red" }}></i>
      </Link>
    );
  };
  const handleUnitSubmit = async () => {
    try {
      if (unitName === "") {
        throw new Error("Unit Name is required");
      } else if (floorUnit === "") {
        throw new Error("Floor / Unit is required");
      } else if (unitType === "") {
        throw new Error("Unit Type is required");
      } else {
        let data = {
          unitName,
          division: currentClickedUnit.divisionName,
          floorUnit,
          unitType,
          lettableFloorArea: unitLettableArea,
          lrNumber,
          grossArea,
          netLettableArea,
        };
        const response = await makeRequest2(
          addUnitUrl + "/" + facilityId,
          "POST",
          data
        );
        if (response.success) {
          getUnits();
          setAddUnitDialogVisible(false);
          setUnitName("");
          setFloorUnit("");
          setUnitType("");
          setLrNumber("");
          setGrossArea("");
          setNetLettableArea("");
          toastify("Unit added successfully", "success");
        } else {
          throw new Error(response.error);
        }
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const nameTemplate = (rowData) => {
    return (
      <Link to={"/core/units/view_unit/" + rowData._id + "/" + facilityId}>
        <strong>{rowData.name}</strong>
      </Link>
    );
  };
  const statusTemplate = (rowData) => {
    if (rowData.status === "Pending Approval") {
      return (
        <span className="badge rounded-pill text-bg-warning">
          Pending Approval
        </span>
      );
    } else {
      return (
        <span className="badge rounded-pill text-bg-success">Approved</span>
      );
    }
  };
  const totalUnitTemplate = (rowData) => {
    return (
      <span className="badge rounded-pill text-bg-success">
        {rowData.selectedCombinedUnits.length}
      </span>
    );
  };
  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === "") {
        if (currentClickedUnit === "All") {
          getUnits();
        } else {
          let filter = units.filter((item) => {
            return item.division === currentClickedUnit.divisionName;
          });
          setCurrentUnits(filter);
        }
      } else {
        if (currentClickedUnit === "All") {
          const results = await filterObjectsByAllProperties(units, value);

          setUnits(results);
        } else {
          const results = await filterObjectsByAllProperties(
            currentUnit,
            value
          );
          setCurrentUnits(results);
        }
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };
  const HandleCombineUnitsSubmit = async () => {
    try {
      if (combinedUnitName === "") {
        toastify("Unit Name is required", "error");
      } else if (combinedDivision === "") {
        toastify("Division is required", "error");
      } else if (combinedUnitType === "") {
        toastify("Unit Type is required", "error");
      } else if (combinedFloorUnit === "") {
        toastify("Floor/Unit is required", "error");
      } else if (combinedLRNumber === "") {
        toastify("LR Number is required", "error");
      } else if (combinedReason === "") {
        toastify("Reason is required", "error");
      } else if (selectedCombinedUnits.length < 2) {
        toastify("Please select atleast 2 units", "error");
      } else {
        const body = {
          combinedUnitName,
          combinedDivision,
          combinedUnitType,
          combinedFloorUnit,
          combinedLettableFloorArea,
          combinedLRNumber,
          combinedGrossArea,
          combinedNetLettableArea,
          combinedReason,
          selectedCombinedUnits,
        };

        const response = await makeRequest2(
          addCombinedUnitURL + "/" + facilityId,
          "POST",
          body
        );
        if (response.success) {
          toastify(response.data, "success");
          setCombineUnits(false);
          setCombineUnitsArray([]);
          setNoCombinedUnits(0);
          setCombinedUnitName("");
          setCombinedDivision("");
          setCombinedUnitType("");
          setCombinedFloorUnit("");
          setCombineLettableFloorArea("");
          setCombinedLRNumber("");
          setCombinedGrossArea("");
          setCombinedNetLettableArea("");
          setCombinedReason("");
          setSelectedCombinedUnits([]);
          getCombinedUnits();
        } else {
          toastify(response.error, "error");
        }
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const HandleDeleteCombinedUnit = async (id) => {
    try {
      const response = await makeRequest2(
        deleteFacilityCombineUnits + "/" + id,
        "GET"
      );
      if (response.success) {
        toastify(response.data, "success");
        getCombinedUnits();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };
  const actionCombinedUnitTemplate = (rowData) => {
    return (
      <>
        <Link
          to={"#"}
          className="avtar avtar-xs btn-link-secondary btn-pc-default"
          onClick={() => {
            setConfirmCombineUnits(true);
            setCurrentConfirmedUnit(rowData);
          }}
        >
          <i className="ti ti-check f-18" style={{ color: "green" }}></i>
        </Link>
        {rowData.status === "Pending Approval" && (
          <Link
            to={"#"}
            className="avtar avtar-xs btn-link-secondary btn-pc-default"
            onClick={() => {
              HandleDeleteCombinedUnit(rowData._id);
            }}
          >
            <i className="ti ti-trash f-18" style={{ color: "red" }}></i>
          </Link>
        )}
      </>
    );
  };
  const handleConfirmCombinedUnit = async () => {
    try {
      const response = await makeRequest2(
        confirmCombinedUnitsURL +
          "/" +
          facilityId +
          "/" +
          currentConfirmUnit._id,
        "GET"
      );
      if (response.success) {
        toastify("Confirmed Successfully", "success");
        getCombinedUnits();
        setConfirmCombineUnits(false);
      } else {
        toastify(response.error, "error");
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const getPaymentDetails = async () => {
    try {
      const response = await makeRequest2(
        `${settingsBaseRoute}/get_facility_payment-details/${facilityId}`,
        "GET"
      );
      console.log(response);

      // Check if we have a successful response with data
      if (response.success && response.data) {
        setExistingPaymentDetails(response.data);
        setHasPaymentDetails(true);
        setPaymentShortCode(response.data.shortCode);
        setPaymentPasskey(response.data.passkey);
        setPaymentAuthKey(response.data.authorizationKey);
        setPaymentModule(response.data.module);
      } else {
        // Reset everything if no data
        setExistingPaymentDetails(null);
        setHasPaymentDetails(false);
        clearForm();
      }
    } catch (err) {
      // Reset everything on error
      setExistingPaymentDetails(null);
      setHasPaymentDetails(false);
      clearForm();
      console.log(err);
      // Don't show error toast for 404 (no data found)
      if (err.message !== "Facility payment details not found") {
        toastify(err.message, "error");
      }
    }
  };

  const handlePaymentDetailsSubmit = async () => {
    try {
      if (
        !paymentShortCode ||
        !paymentPasskey ||
        !paymentAuthKey ||
        !paymentModule
      ) {
        throw new Error("Please fill all required fields");
      }

      const data = {
        facility: facilityId,
        shortCode: paymentShortCode,
        passkey: paymentPasskey,
        authorizationKey: paymentAuthKey,
        module: paymentModule,
      };

      const url = hasPaymentDetails
        ? `${settingsBaseRoute}/update_payment-details`
        : `${settingsBaseRoute}/add_payment_details`;

      const method = hasPaymentDetails ? "PUT" : "POST";

      const response = await makeRequest2(url, method, data);

      if (response) {
        toastify(
          `Payment details ${
            hasPaymentDetails ? "updated" : "added"
          } successfully`,
          "success"
        );
        // Reset forms and refresh data
        setShowPaymentForm(false);
        setShowEditForm(false);
        clearForm();
        getPaymentDetails();
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const clearForm = () => {
    setPaymentShortCode("");
    setPaymentPasskey("");
    setPaymentAuthKey("");
    setPaymentModule("");
  };

  const handleEdit = () => {
    setShowEditForm(true);
  };

  const handleCancel = () => {
    setShowPaymentForm(false);
    setShowEditForm(false);
    clearForm();
  };

  useEffect(() => {
    getFacility();
    getCombinedUnits();
    getPaymentDetails();
    setTimeout(() => {
      getUnits();
    }, 2000);
  }, []);

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button
        type="button"
        label="CSV"
        rounded
        onClick={() => {}}
        data-pr-tooltip="CSV"
      />
      <Button
        type="button"
        label="XLS"
        severity="success"
        rounded
        onClick={() => {}}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        label="PDF"
        severity="warning"
        rounded
        onClick={() => {}}
        data-pr-tooltip="PDF"
      />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Facilities</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>View Facility</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <Link to={"#"} onClick={() => navigate(-1)}>
                            <span>
                              <i className="ti ti-arrow-narrow-left"></i> Back
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="profile-tab-1"
                                data-bs-toggle="tab"
                                href="#profile-1"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-building-bank me-2"></i>Info
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-2"
                                data-bs-toggle="tab"
                                href="#profile-2"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-building-warehouse me-2"></i>
                                Units
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-3"
                                data-bs-toggle="tab"
                                href="#profile-3"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-arrows-join me-2"></i>
                                Combined Units
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-4"
                                data-bs-toggle="tab"
                                href="#profile-4"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-olympics me-2"></i>Assets
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-5"
                                data-bs-toggle="tab"
                                href="#profile-5"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-settings me-2"></i>Settings
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-6"
                                data-bs-toggle="tab"
                                href="#profile-6"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-settings me-2"></i>Payment
                                Settings
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="profile-1"
                          role="tabpanel"
                          aria-labelledby="profile-tab-1"
                        >
                          <div className="row">
                            <div className="col-lg-4 col-xxl-3">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="text-center mt-3">
                                    <div className="chat-avtar d-inline-flex mx-auto">
                                      <i
                                        className="ti ti-building-bank me-2"
                                        style={{ fontSize: 100 }}
                                      ></i>
                                    </div>
                                    <h5 className="mb-0"></h5>
                                    <h2 className="text-muted mt-2">
                                      {facility.name}
                                    </h2>
                                    <hr className="my-3 border border-secondary-subtle" />
                                    <div className="row g-3">
                                      <div className="col-4"></div>
                                      <div className="col-4 border border-top-0 border-bottom-0">
                                        <h5 className="mb-0">{units.length}</h5>
                                        <small className="text-muted">
                                          Units
                                        </small>
                                      </div>
                                      <div className="col-4"></div>
                                    </div>
                                    <hr className="my-3 border border-secondary-subtle" />

                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                      <p className="mb-0">
                                        <strong>Location: </strong>
                                        {facility.location}
                                      </p>
                                    </div>
                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                      <p className="mb-0">
                                        <strong>
                                          Total Common Areas:{" "}
                                          {facilityCommonArea}{" "}
                                          {defaultMeasurement.symbol}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                      <p className="mb-0">
                                        <strong>
                                          Total Lettable Areas: {lettableArea}{" "}
                                          {defaultMeasurement.symbol}
                                        </strong>
                                      </p>
                                    </div>

                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                      <span>: </span>
                                      <p className="mb-0"></p>
                                    </div>
                                    <div className="w-100 mb-3">
                                      {facility.isEnabled && (
                                        <button
                                          className="btn btn-success btn-block"
                                          onClick={() => {}}
                                          disabled
                                        >
                                          Enabled
                                        </button>
                                      )}
                                      {!facility.isEnabled && (
                                        <button
                                          className="btn btn-danger btn-block"
                                          onClick={() => {}}
                                          disabled
                                        >
                                          Disabled
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-2"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="mail-wrapper">
                                    <div
                                      className="offcanvas-xxl offcanvas-start mail-offcanvas"
                                      tabindex="-1"
                                      id="offcanvas_mail"
                                    >
                                      <div className="offcanvas-header">
                                        <button
                                          className="btn-close"
                                          data-bs-dismiss="offcanvas"
                                          data-bs-target="#offcanvas_mail"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div className="offcanvas-body p-0">
                                        <div
                                          id="mail-menulist"
                                          className="show collapse collapse-horizontal"
                                        >
                                          <div className="mail-menulist">
                                            <div className="card">
                                              <div className="card-body">
                                                <div className="d-grid">
                                                  <button
                                                    className="btn btn-primary d-flex align-items-center justify-content-center"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#compose_mail_modal"
                                                    onClick={() => {
                                                      navigate(
                                                        "/core/units/import_units/" +
                                                          facilityId
                                                      );
                                                    }}
                                                  >
                                                    <i className="ti ti-file-download me-2"></i>{" "}
                                                    Import Units
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="scroll-block">
                                                <div className="card-body pt-0">
                                                  <div
                                                    className="list-group list-group-flush"
                                                    style={{
                                                      overflowY: "auto",
                                                      height: 500,
                                                    }}
                                                    id="list-tab"
                                                    role="tablist"
                                                  >
                                                    <a
                                                      className={`list-group-item list-group-item-action ${
                                                        currentClickedUnit ===
                                                          "All" && "active"
                                                      }`}
                                                      id="list-mailtab-1"
                                                      data-bs-toggle="list"
                                                      href={`#list-mail-`}
                                                      role="tab"
                                                      onClick={() => {
                                                        setCurrentClickedUnit(
                                                          "All"
                                                        );
                                                      }}
                                                    >
                                                      <span>
                                                        <i className="ti ti-square-rotated"></i>
                                                        All
                                                      </span>
                                                    </a>
                                                    {facility.divisionArray !==
                                                      undefined && (
                                                      <>
                                                        {facility.divisionArray.map(
                                                          (item, index) => {
                                                            return (
                                                              <a
                                                                className={`list-group-item list-group-item-action ${
                                                                  item.divisionName ===
                                                                    currentClickedUnit.divisionName &&
                                                                  `active`
                                                                }`}
                                                                id="list-mailtab-1"
                                                                data-bs-toggle="list"
                                                                href={`#list-mail-${
                                                                  index + 1
                                                                }`}
                                                                role="tab"
                                                                onClick={() => {
                                                                  setCurrentClickedUnit(
                                                                    item
                                                                  );
                                                                  let filter =
                                                                    units.filter(
                                                                      (x) => {
                                                                        return (
                                                                          x.division ===
                                                                          item.divisionName
                                                                        );
                                                                      }
                                                                    );
                                                                  setCurrentUnits(
                                                                    []
                                                                  );
                                                                  setCurrentUnits(
                                                                    filter
                                                                  );
                                                                }}
                                                              >
                                                                <span>
                                                                  <i className="ti ti-square-rotated"></i>
                                                                  {
                                                                    item.divisionName
                                                                  }
                                                                </span>
                                                                <span className="avtar avtar-xs">
                                                                  {
                                                                    item.NoOfFloors
                                                                  }
                                                                </span>
                                                              </a>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mail-content">
                                      <div className="d-sm-flex align-items-center">
                                        <ul className="list-inline ms-auto mb-3">
                                          <li className="list-inline-item">
                                            <button
                                              className="btn btn-warning d-flex align-items-center justify-content-center"
                                              onClick={() => {
                                                setCombineUnits(true);
                                              }}
                                            >
                                              <i className="ti ti-arrows-join me-2"></i>{" "}
                                              Combine Units
                                            </button>
                                          </li>
                                          <li className="list-inline-item">
                                            <div className="form-search">
                                              <i className="ti ti-search"></i>
                                              <input
                                                type="search"
                                                className="form-control"
                                                placeholder="Search Here"
                                                value={searchTerm}
                                                onChange={(e) => {
                                                  handleInputSearch(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="card">
                                        <div className="card-body">
                                          <div
                                            className="tab-content"
                                            id="nav-tabContent"
                                          >
                                            {currentClickedUnit === "All" ? (
                                              <>
                                                <div className="card-body">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <DataTable
                                                        value={units}
                                                        emptyMessage="No units found."
                                                        sortMode="multiple"
                                                        paginator
                                                        rows={10}
                                                        stripedRows
                                                        tableStyle={{
                                                          minWidth: "50rem",
                                                        }}
                                                      >
                                                        <Column
                                                          field="name"
                                                          sortable
                                                          header="Name"
                                                          body={nameTemplate}
                                                        ></Column>
                                                        <Column
                                                          field="unitType"
                                                          header="Type"
                                                        ></Column>
                                                        <Column
                                                          field="floorUnitNo"
                                                          header="Floor/Unit"
                                                        ></Column>
                                                        <Column
                                                          field="lettableFloorArea"
                                                          header="LFA"
                                                        ></Column>
                                                        <Column
                                                          field="landRateNumber"
                                                          header="LRN"
                                                        ></Column>
                                                        <Column
                                                          field="grossArea"
                                                          header="GA"
                                                        ></Column>
                                                        <Column
                                                          field="netLettableArea"
                                                          header="NLA"
                                                        ></Column>
                                                      </DataTable>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {facility.divisionArray !==
                                                  undefined && (
                                                  <>
                                                    {facility.divisionArray.map(
                                                      (item, index) => {
                                                        return (
                                                          <div
                                                            className={`tab-pane fade ${
                                                              currentClickedUnit.divisionName ===
                                                                item.divisionName &&
                                                              `show active`
                                                            }`}
                                                            id={`list-mail-${
                                                              index + 1
                                                            }`}
                                                            role="tabpanel"
                                                            aria-labelledby="list-mailtab-1"
                                                          >
                                                            <div className="card">
                                                              <div className="card-header">
                                                                <div>
                                                                  <strong>
                                                                    {
                                                                      item.divisionName
                                                                    }
                                                                  </strong>
                                                                  <div
                                                                    style={{
                                                                      float:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    <button
                                                                      className="btn btn-primary"
                                                                      onClick={() => {
                                                                        setAddUnitDialogVisible(
                                                                          true
                                                                        );
                                                                      }}
                                                                    >
                                                                      <i className="ti ti-circle-plus me-2"></i>{" "}
                                                                      Add Unit
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="card-body">
                                                                <div className="row">
                                                                  <div className="col-md-12">
                                                                    <DataTable
                                                                      value={
                                                                        currentUnit
                                                                      }
                                                                      emptyMessage="No units found."
                                                                      sortMode="multiple"
                                                                      paginator
                                                                      rows={10}
                                                                      stripedRows
                                                                      tableStyle={{
                                                                        minWidth:
                                                                          "50rem",
                                                                      }}
                                                                    >
                                                                      <Column
                                                                        field="name"
                                                                        sortable
                                                                        header="Name"
                                                                        body={
                                                                          nameTemplate
                                                                        }
                                                                      ></Column>
                                                                      <Column
                                                                        field="unitType"
                                                                        header="Type"
                                                                      ></Column>
                                                                      <Column
                                                                        field="floorUnitNo"
                                                                        header="Floor/Unit"
                                                                      ></Column>
                                                                      <Column
                                                                        field="lettableFloorArea"
                                                                        header="LFA"
                                                                      ></Column>
                                                                      <Column
                                                                        field="landRateNumber"
                                                                        header="LRN"
                                                                      ></Column>
                                                                      <Column
                                                                        field="grossArea"
                                                                        header="GA"
                                                                      ></Column>
                                                                      <Column
                                                                        field="netLettableArea"
                                                                        header="NLA"
                                                                      ></Column>
                                                                    </DataTable>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-3"
                          role="tabpanel"
                          aria-labelledby="profile-tab-3"
                        >
                          <div className="row">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <DataTable
                                    value={combinedUnits}
                                    emptyMessage="No data found."
                                    sortMode="multiple"
                                    paginator
                                    rows={10}
                                    stripedRows
                                    tableStyle={{ minWidth: "50rem" }}
                                  >
                                    <Column
                                      field="combinedUnitName"
                                      sortable
                                      header="Name"
                                    ></Column>
                                    <Column
                                      field="combinedUnitType"
                                      header="Unit Type"
                                    ></Column>
                                    <Column
                                      field="combinedDivision"
                                      header="Division"
                                    ></Column>
                                    <Column
                                      field="combinedFloorUnit"
                                      header="Floor / Unit"
                                    ></Column>
                                    <Column
                                      field="combinedLRNumber"
                                      header="LRN"
                                    ></Column>
                                    <Column
                                      field="combinedGrossArea"
                                      header="GRA"
                                    ></Column>
                                    <Column
                                      field="combinedLettableFloorArea"
                                      header="LFA"
                                    ></Column>
                                    <Column
                                      field="combinedNetLettableArea"
                                      header="NLA"
                                    ></Column>
                                    <Column
                                      field="combinedReason"
                                      header="Reason"
                                    ></Column>
                                    <Column
                                      field="Unit"
                                      header="Units"
                                      body={totalUnitTemplate}
                                    ></Column>
                                    <Column
                                      field="status"
                                      sortable
                                      body={statusTemplate}
                                      header="Status"
                                    ></Column>
                                    <Column
                                      field="action"
                                      body={actionCombinedUnitTemplate}
                                      header="Action"
                                    ></Column>
                                  </DataTable>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-4"
                          role="tabpanel"
                          aria-labelledby="profile-tab-4"
                        >
                          <div className="row">
                            <div className="card-header">
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setNewAssetDialog(true);
                                  }}
                                >
                                  Add New Asset
                                </button>
                              </div>
                            </div>

                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <DataTable
                                    value={assets}
                                    emptyMessage="No assets found."
                                    sortMode="multiple"
                                    paginator
                                    rows={10}
                                    stripedRows
                                    tableStyle={{ minWidth: "50rem" }}
                                  >
                                    <Column
                                      field="name"
                                      sortable
                                      header="Name"
                                    ></Column>
                                    <Column
                                      field="action"
                                      sortable
                                      body={deleteAssetTemplate}
                                      header="Action"
                                    ></Column>
                                  </DataTable>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane"
                          id="profile-5"
                          role="tabpanel"
                          aria-labelledby="profile-tab-5"
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-body py-0">
                                  <ul
                                    className="nav nav-tabs profile-tabs"
                                    id="myTab"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        id="setting-tab-1"
                                        data-bs-toggle="tab"
                                        href="#setting-1"
                                        role="tab"
                                        aria-selected="true"
                                      >
                                        <i className="ti ti-stack-2 me-2"></i>
                                        Measurements
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="setting-tab-2"
                                        data-bs-toggle="tab"
                                        href="#setting-2"
                                        role="tab"
                                        aria-selected="true"
                                      >
                                        <i className="ti ti-picture-in-picture me-2"></i>
                                        Common Area
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="setting-tab-3"
                                        data-bs-toggle="tab"
                                        href="#setting-3"
                                        role="tab"
                                        aria-selected="true"
                                      >
                                        <i className="ti ti-notification me-2"></i>
                                        Lettable Area
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="setting-tab-4"
                                        data-bs-toggle="tab"
                                        href="#setting-4"
                                        role="tab"
                                        aria-selected="true"
                                      >
                                        <i className="ti ti-container me-2"></i>
                                        Land Reference Numbers
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="setting-tab-5"
                                        data-bs-toggle="tab"
                                        href="#setting-5"
                                        role="tab"
                                        aria-selected="true"
                                      >
                                        <i className="ti ti-edit me-2"></i>Edits
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="tab-content">
                                <div
                                  className="tab-pane active"
                                  id="setting-1"
                                  role="tabpanel"
                                  aria-labelledby="setting-tab-1"
                                >
                                  <div className="row">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4">
                                      <div className="card">
                                        <div className="card-header">
                                          <h5>
                                            Default Measurement ({" "}
                                            {defaultMeasurement.name} )
                                          </h5>
                                        </div>
                                        <div className="card-body">
                                          <select
                                            className="form-control"
                                            value={defaultMeasurement.name}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setDefaultMeasurement(value);
                                            }}
                                          >
                                            <option value="">
                                              -- Select --
                                            </option>
                                            {areaMeasurements !== undefined &&
                                              areaMeasurements.map((item) => {
                                                return (
                                                  <option
                                                    value={JSON.stringify(item)}
                                                  >
                                                    {item.name}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                          <div style={{ float: "right" }}>
                                            <button
                                              className="mt-3 btn btn-primary"
                                              onClick={HandleDefaultMeasurement}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4"></div>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane "
                                  id="setting-2"
                                  role="tabpanel"
                                  aria-labelledby="setting-tab-2"
                                >
                                  <div className="row">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4">
                                      <div className="card">
                                        <div className="card-body">
                                          <label>
                                            Total Common Area ({" "}
                                            {defaultMeasurement.symbol} )
                                          </label>
                                          <input
                                            className="form-control mt-2"
                                            type="number"
                                            placeholder="Enter Total Common Area"
                                            value={facilityCommonArea}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFacilityCommonArea(value);
                                            }}
                                          />
                                          <div style={{ float: "right" }}>
                                            <button
                                              className="mt-3 btn btn-primary"
                                              onClick={
                                                HandleFacilityCommonAreaSubmit
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4"></div>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane "
                                  id="setting-3"
                                  role="tabpanel"
                                  aria-labelledby="setting-tab-3"
                                >
                                  <div className="row">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4">
                                      <div className="card">
                                        <div className="card-body">
                                          <label>
                                            Total Lettable Area ({" "}
                                            {defaultMeasurement.symbol} )
                                          </label>
                                          <input
                                            className="form-control mt-2"
                                            type="number"
                                            placeholder="Enter Total Lettable Area"
                                            value={lettableArea}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setLettableArea(value);
                                            }}
                                          ></input>
                                          <div style={{ float: "right" }}>
                                            <button
                                              className="mt-3 btn btn-primary"
                                              onClick={HandleLettableAreaSubmit}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4"></div>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane "
                                  id="setting-4"
                                  role="tabpanel"
                                  aria-labelledby="setting-tab-4"
                                >
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="card">
                                        <div className="card-body">
                                          <div className="mt-2">
                                            <label>LR Number</label>
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              placeholder="Enter LR Number"
                                              value={LRNumber}
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                setLRNumber(value);
                                              }}
                                            />
                                          </div>

                                          <div style={{ float: "right" }}>
                                            <button
                                              className="mt-3 btn btn-primary"
                                              onClick={HandleLRNumberSubmit}
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <ul className="list-group">
                                        {facility.landReferenceNumbers !==
                                          undefined &&
                                          facility.landReferenceNumbers.map(
                                            (item, index) => {
                                              return (
                                                <li
                                                  className="list-group-item"
                                                  key={item}
                                                >
                                                  {index + 1}. {item}{" "}
                                                  <div
                                                    style={{ float: "right" }}
                                                  >
                                                    <Link
                                                      to={"#"}
                                                      onClick={() => {
                                                        HandleDeleteLRNumber(
                                                          item
                                                        );
                                                      }}
                                                    >
                                                      <i
                                                        className="ti ti-trash f-18"
                                                        style={{ color: "red" }}
                                                      ></i>
                                                    </Link>{" "}
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane "
                                  id="setting-5"
                                  role="tabpanel"
                                  aria-labelledby="setting-tab-5"
                                >
                                  <div className="row">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4">
                                      <div className="card">
                                        <div className="card-body">
                                          <div className="mt-2">
                                            <label>Facility Name</label>
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              placeholder="Enter Facility Name"
                                              value={facilityName}
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                setFacilityName(value);
                                              }}
                                            />
                                          </div>
                                          <div className="mt-2">
                                            <label>Facility Location</label>
                                            <AddressAutofill
                                              accessToken={
                                                "pk.eyJ1IjoiZXZhbnMtbmpvZ3UiLCJhIjoiY2s3dzdrM3V5MDFmNDNkcGRqZDdzcTR1eCJ9.pk-MT1CGAs8FutVkiyWs1g"
                                              }
                                            >
                                              <input
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="Enter Facility Location"
                                                value={facilityLocation}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  setFacilityLocation(value);
                                                }}
                                              />
                                            </AddressAutofill>
                                          </div>

                                          <div style={{ float: "right" }}>
                                            <button
                                              className="mt-3 btn btn-primary"
                                              onClick={HandleFacilityInfoSubmit}
                                            >
                                              Update
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Payment Settings Tab */}
                        <div
                          className="tab-pane"
                          id="profile-6"
                          role="tabpanel"
                          aria-labelledby="profile-tab-6"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                  <h5>Payment Settings</h5>
                                  {!hasPaymentDetails && !showPaymentForm && (
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => setShowPaymentForm(true)}
                                    >
                                      Add Payment Details
                                    </button>
                                  )}
                                </div>
                                <div className="card-body">
                                  {/* Show this when no data and no form */}
                                  {!hasPaymentDetails && !showPaymentForm && (
                                    <div className="text-center p-4">
                                      <p>
                                        No payment details have been added yet.
                                      </p>
                                      <p>
                                        Click "Add Payment Details" to set up
                                        payment configuration.
                                      </p>
                                    </div>
                                  )}

                                  {/* Display existing payment details */}
                                  {hasPaymentDetails && !showEditForm && (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="card">
                                          <div className="card-body">
                                            <div className="row mb-3">
                                              <div className="col-md-6">
                                                <p>
                                                  <strong>Short Code:</strong>{" "}
                                                  {
                                                    existingPaymentDetails?.shortCode
                                                  }
                                                </p>
                                                <p>
                                                  <strong>Module:</strong>{" "}
                                                  {
                                                    existingPaymentDetails?.module
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-md-6">
                                                <p>
                                                  <strong>Passkey:</strong>{" "}
                                                  ********
                                                </p>
                                                <p>
                                                  <strong>
                                                    Authorization Key:
                                                  </strong>{" "}
                                                  ********
                                                </p>
                                              </div>
                                            </div>
                                            <div className="text-end">
                                              <button
                                                className="btn btn-primary"
                                                onClick={handleEdit}
                                              >
                                                Edit Details
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* Add Payment Form */}
                                  {showPaymentForm && !hasPaymentDetails && (
                                    <div className="row">
                                      <div className="col-md-8 mx-auto">
                                        <div className="card">
                                          <div className="card-header">
                                            <h5>Add Payment Details</h5>
                                          </div>
                                          <div className="card-body">
                                            <div className="form-group mb-3">
                                              <label>
                                                Short Code
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Short Code"
                                                value={paymentShortCode}
                                                onChange={(e) =>
                                                  setPaymentShortCode(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="form-group mb-3">
                                              <label>
                                                Passkey
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter Passkey"
                                                value={paymentPasskey}
                                                onChange={(e) =>
                                                  setPaymentPasskey(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="form-group mb-3">
                                              <label>
                                                Authorization Key
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter Authorization Key"
                                                value={paymentAuthKey}
                                                onChange={(e) =>
                                                  setPaymentAuthKey(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="form-group mb-3">
                                              <label>
                                                Module
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <select
                                                className="form-control"
                                                value={paymentModule}
                                                onChange={(e) =>
                                                  setPaymentModule(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  -- Select --
                                                </option>
                                                <option value="All">All</option>
                                                <option value="Water">
                                                  Water
                                                </option>
                                                <option value="Electricity">
                                                  Electricity
                                                </option>
                                                <option value="Levy">
                                                  Levy
                                                </option>
                                              </select>
                                            </div>

                                            <div className="text-end">
                                              <button
                                                className="btn btn-light me-2"
                                                onClick={handleCancel}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                className="btn btn-primary"
                                                onClick={
                                                  handlePaymentDetailsSubmit
                                                }
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* Edit Payment Form */}
                                  {showEditForm && hasPaymentDetails && (
                                    <div className="row">
                                      <div className="col-md-8 mx-auto">
                                        <div className="card">
                                          <div className="card-header">
                                            <h5>Edit Payment Details</h5>
                                          </div>
                                          <div className="card-body">
                                            <div className="form-group mb-3">
                                              <label>
                                                Short Code
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Short Code"
                                                value={paymentShortCode}
                                                onChange={(e) =>
                                                  setPaymentShortCode(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="form-group mb-3">
                                              <label>
                                                Passkey
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter Passkey"
                                                value={paymentPasskey}
                                                onChange={(e) =>
                                                  setPaymentPasskey(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="form-group mb-3">
                                              <label>
                                                Authorization Key
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter Authorization Key"
                                                value={paymentAuthKey}
                                                onChange={(e) =>
                                                  setPaymentAuthKey(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="form-group mb-3">
                                              <label>
                                                Module
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <select
                                                className="form-control"
                                                value={paymentModule}
                                                onChange={(e) =>
                                                  setPaymentModule(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  -- Select --
                                                </option>
                                                <option value="All">All</option>
                                                <option value="Water">
                                                  Water
                                                </option>
                                                <option value="Electricity">
                                                  Electricity
                                                </option>
                                                <option value="Levy">
                                                  Levy
                                                </option>
                                              </select>
                                            </div>

                                            <div className="text-end">
                                              <button
                                                className="btn btn-light me-2"
                                                onClick={handleCancel}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                className="btn btn-primary"
                                                onClick={
                                                  handlePaymentDetailsSubmit
                                                }
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* End of payment details */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header={`Confirm Combining Units`}
        visible={confirmCombineUnits}
        style={{ width: "30vw" }}
        onHide={() => {
          if (!confirmCombineUnits) return;
          setConfirmCombineUnits(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <strong>
              By clicking 'Confirm,' you agree to archive the selected units and
              create a new combined unit.
            </strong>

            <div className="mt-4" style={{ float: "right" }}>
              <button
                className="btn btn-primary"
                onClick={handleConfirmCombinedUnit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={`Add a New Asset`}
        visible={newAssetDialog}
        style={{ width: "30vw" }}
        onHide={() => {
          if (!newAssetDialog) return;
          setNewAssetDialog(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div>
              <label>Asset Name:</label>
              <input
                className="form-control mt-2"
                type="text"
                placeholder="Enter Asset Name"
                value={assetName}
                onChange={(e) => {
                  const value = e.target.value;
                  setAssetName(value);
                }}
              ></input>
            </div>

            <div className="mt-4" style={{ float: "right" }}>
              <button className="btn btn-primary" onClick={HandleAssetSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={`Add a New Unit`}
        visible={addUnitDialog}
        style={{ width: "30vw" }}
        onHide={() => {
          if (!addUnitDialog) return;
          setAddUnitDialogVisible(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div>
              <label>Unit Name:</label>
              <input
                className="form-control mt-2"
                type="text"
                placeholder="Enter unit name"
                value={unitName}
                onChange={(e) => {
                  let value = e.target.value;
                  setUnitName(value);
                }}
              ></input>
            </div>
            <div className="mt-3">
              <label>Unit Type:</label>
              <select
                className="form-control"
                type="text"
                value={unitType}
                onChange={(e) => {
                  let value = e.target.value;
                  setUnitType(value);
                }}
              >
                <option>-- Select --</option>
                {unitTypes !== undefined &&
                  unitTypes.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
              </select>
            </div>
            <div className="mt-3">
              <label>Floor / Unit:</label>
              <select
                className="form-control mt-2"
                value={floorUnit}
                onChange={(e) => {
                  let value = e.target.value;
                  setFloorUnit(value);
                }}
              >
                <option value="">-- Select --</option>
                {currentClickedUnit.NoOfFloors === 0 ? (
                  <option value="0">Floor / Unit 0</option>
                ) : (
                  <>
                    <option value={currentClickedUnit.NoOfFloors}>
                      Floor / Unit {currentClickedUnit.NoOfFloors}
                    </option>
                    {Array.from({ length: currentClickedUnit.NoOfFloors }).map(
                      (_, index) => (
                        <option key={index} value={index}>
                          Floor / Unit {index}
                        </option>
                      )
                    )}
                  </>
                )}
              </select>
            </div>
            <div className="mt-3">
              <label>Lettable Floor Area:</label>
              <input
                className="form-control mt-2"
                type="number"
                placeholder="Enter Lettable Floor Area"
                value={unitLettableArea}
                onChange={(e) => {
                  let value = e.target.value;
                  setUnitLettableArea(value);
                }}
              ></input>
            </div>
            <div className="mt-3">
              <label>LR Number:</label>
              <select
                className="form-control mt-2"
                type="number"
                placeholder="Enter LR Number"
                value={lrNumber}
                onChange={(e) => {
                  let value = e.target.value;
                  setLrNumber(value);
                }}
              >
                <option value="">-- Select --</option>
                {facility.landReferenceNumbers !== undefined &&
                  facility.landReferenceNumbers.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
              </select>
            </div>
            <div className="mt-3">
              <label>Gross Area:</label>
              <input
                className="form-control mt-2"
                type="number"
                placeholder="Enter Gross Area"
                value={grossArea}
                onChange={(e) => {
                  let value = e.target.value;
                  setGrossArea(value);
                }}
              ></input>
            </div>
            <div className="mt-3">
              <label>Net Lettable Area:</label>
              <input
                className="form-control mt-2"
                type="number"
                placeholder="Enter Net Lettable Area"
                value={netLettableArea}
                onChange={(e) => {
                  let value = e.target.value;
                  setNetLettableArea(value);
                }}
              ></input>
            </div>
            <div className="mt-4" style={{ float: "right" }}>
              <button className="btn btn-primary" onClick={handleUnitSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={`Combine Units`}
        visible={combineUnits}
        style={{ width: "30vw" }}
        onHide={() => {
          if (!combineUnits) return;
          setCombineUnits(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div>
              <label>Number of Units: </label>
              <input
                className="form-control mt-2"
                type="number"
                placeholder="Enter Number Units"
                value={noCombinedUnits}
                onChange={(e) => {
                  const value = e.target.value;

                  if (value !== 0) {
                    if (value <= units.length) {
                      let d = [];
                      for (let i = 0; i < value; i++) {
                        d.push(i);
                      }
                      setNoCombinedUnits(value);
                      setCombineUnitsArray(d);
                      setSelectedCombinedUnits([]);
                    }
                    if (value === 1) {
                      toastify(value + " should be greater than 2", "error");
                    } else {
                      toastify(
                        value + " exceends the number of total units",
                        "error"
                      );
                    }
                  }
                }}
              ></input>
            </div>

            {combineUnitsArray.map((item, index) => {
              return (
                <div
                  className="mt-3"
                  style={{ backgroundColor: "whitesmoke", padding: 10 }}
                >
                  <label>Choose Unit {index + 1}</label>
                  <select
                    className="form-control mt-2"
                    type="text"
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value !== "") {
                        const filter = selectedCombinedUnits.filter((x) => {
                          return x === value;
                        });

                        if (filter.length > 0) {
                          toastify("Unit has already been selected.", "error");
                        } else {
                          let array = selectedCombinedUnits;
                          array.push(value);
                          setSelectedCombinedUnits(array);
                        }
                      } else {
                        let array = selectedCombinedUnits;
                        array.splice(index, 1);
                        setSelectedCombinedUnits(array);
                      }
                    }}
                  >
                    <option value="">-- Select --</option>
                    {units.map((x) => {
                      return <option value={x._id}>{x.name}</option>;
                    })}
                  </select>
                </div>
              );
            })}
            <div className="mt-3">
              <label>Unit Name</label>
              <input
                className="form-control mt-1"
                type="text"
                placeholder="Unit Name"
                value={combinedUnitName}
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedUnitName(value);
                }}
              />
            </div>
            <div className="mt-3">
              <label>Division</label>
              <select
                className="form-control mt-2"
                type="text"
                placeholder="Divison"
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedDivision(value);
                }}
              >
                <option>-- Select --</option>
                {facility.divisionArray !== undefined &&
                  facility.divisionArray.map((item) => {
                    return (
                      <option value={item.divisionName}>
                        {item.divisionName}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="mt-3">
              <label>Unit Type:</label>
              <select
                className="form-control mt-2"
                type="text"
                value={combinedUnitType}
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedUnitType(value);
                }}
              >
                <option>-- Select --</option>
                {unitTypes !== undefined &&
                  unitTypes.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
              </select>
            </div>

            <div className="mt-3">
              <label>Floor / Unit:</label>
              <select
                className="form-control mt-2"
                value={combinedFloorUnit}
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedFloorUnit(value);
                }}
              >
                <option value="">-- Select --</option>
                {currentClickedUnit.NoOfFloors === 0 ? (
                  <option value="0">Floor / Unit 0</option>
                ) : (
                  <>
                    <option value={currentClickedUnit.NoOfFloors}>
                      Floor / Unit {currentClickedUnit.NoOfFloors}
                    </option>
                    {Array.from({ length: currentClickedUnit.NoOfFloors }).map(
                      (_, index) => (
                        <option key={index} value={index}>
                          Floor / Unit {index}
                        </option>
                      )
                    )}
                  </>
                )}
              </select>
            </div>
            <div className="mt-3">
              <label>Lettable Floor Area:</label>
              <input
                className="form-control mt-2"
                type="number"
                placeholder="Enter Lettable Floor Area"
                value={combinedLettableFloorArea}
                onChange={(e) => {
                  let value = e.target.value;
                  setCombineLettableFloorArea(value);
                }}
              ></input>
            </div>
            <div className="mt-3">
              <label>LR Number:</label>
              <select
                className="form-control mt-2"
                type="number"
                placeholder="Enter LR Number"
                value={combinedLRNumber}
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedLRNumber(value);
                }}
              >
                <option value="">-- Select --</option>
                {facility.landReferenceNumbers !== undefined &&
                  facility.landReferenceNumbers.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
              </select>
            </div>
            <div className="mt-3">
              <label>Gross Area:</label>
              <input
                className="form-control mt-2"
                type="number"
                placeholder="Enter Gross Area"
                value={combinedGrossArea}
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedGrossArea(value);
                }}
              ></input>
            </div>
            <div className="mt-3">
              <label>Net Lettable Area:</label>
              <input
                className="form-control mt-2"
                type="number"
                placeholder="Enter Net Lettable Area"
                value={combinedNetLettableArea}
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedNetLettableArea(value);
                }}
              ></input>
            </div>
            <div className="mt-3">
              <label>Reason For Combining:</label>
              <textarea
                className="form-control mt-2"
                type="text"
                placeholder="Reason for combination"
                onChange={(e) => {
                  let value = e.target.value;
                  setCombinedReason(value);
                }}
              >
                {combinedReason}
              </textarea>
            </div>

            <div className="mt-4" style={{ float: "right" }}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  HandleCombineUnitsSubmit();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
}

export default ViewFacility;
