import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { ConfirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import Layout from '../component/layout';
import { countries } from '../../../utils/data';
import ConfirmDeleteDialog from '../component/confirmDialog';


function Configs() {
  // concentrators
  const [concentrators, setConcentrators] = useState([]);
  const [filteredConcentrators, setFilteredConcentrators] = useState(null);
  const [selectedConcentrator, setSelectedConcentrator] = useState(null);

  // State for meter sizes
  const [meterSizes, setMeterSizes] = useState([]);
  const [newMeterSize, setNewMeterSize] = useState('');

  // State for protocols
  const [protocols, setProtocols] = useState([]);
  const [newProtocol, setNewProtocol] = useState('');

  // State for manufacturers
  const [manufacturers, setManufacturers] = useState([]);
  const [manufacturerForm, setManufacturerForm] = useState({
    name: '',
    country: '',
    contactPerson: '',
    phoneNumber: '',
    email: ''
  });

  // State for IOT cards
  const [iotCards, setIotCards] = useState([]);
  const [selectedIotCard, setSelectedIotCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addIotCardVisible, setAddIotCardVisible] = useState(false);
  const [editIotCardVisible, setEditIotCardVisible] = useState(false);

  //add  
  const [newIotCardForm, setNewIotCardForm] = useState({
    serialNumber: '',
    provider: '',
    status: 'inactive'
  });

  //edit
  const [editIotCardForm, setEditIotCardForm] = useState({
    serialNumber: '',
    provider: '',
    concentrator: '',
    location: '',
    status: 'inactive'
  });

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState('');


  // Base URLs from endpoints
  const waterMeterSizeBaseUrl = '/api/core/water-meters-sizes';
  const waterMeterProtocolsBaseUrl = '/api/core/water-meter-communcation-protocol';
  const waterMeterManufacturerBaseUrl = '/api/core/water-meter-manufacturer';
  const waterMeterIotCardsBaseUrl = '/api/core/water-meter-iot-cards';
  const concentratorBaseUrl = '/api/core/concentrators';

  useEffect(() => {
    fetchAllData();
    fetchConcentrators();
  }, []);

  const fetchAllData = () => {
    fetchMeterSizes();
    fetchProtocols();
    fetchManufacturers();
    fetchIotCards();
  };

  const fetchConcentrators = async () => {
    try {
      const response = await makeRequest2(concentratorBaseUrl, 'GET');
      if (response.success) {
        setConcentrators(response.data || []);
      } else {
        toastify(response.error || 'Failed to fetch concentrators', 'error');
      }
    } catch (error) {
      toastify('An error occurred while fetching concentrators', 'error');
    }
  };

  const fetchMeterSizes = async () => {
    try {

      const response = await makeRequest2(waterMeterSizeBaseUrl, 'GET');

      if (response.success) {
        const data = response.data || [];

        setMeterSizes(data);
      } else {
        toastify(response.error || 'Failed to fetch meter sizes', 'error');
        setMeterSizes([]);
      }
    } catch (error) {

      toastify('An error occurred while fetching meter sizes', 'error');
      setMeterSizes([]);
    }
  };

  const fetchProtocols = async () => {
    try {

      const response = await makeRequest2(waterMeterProtocolsBaseUrl, 'GET');

      if (response.success) {
        const data = response.data || [];

        setProtocols(data);
      } else {
        toastify(response.error || 'Failed to fetch protocols', 'error');
        setProtocols([]);
      }
    } catch (error) {
      toastify('An error occurred while fetching protocols', 'error');
      setProtocols([]);
    }
  };

  const fetchManufacturers = async () => {
    try {
      const response = await makeRequest2(waterMeterManufacturerBaseUrl, 'GET');

      if (response.success) {
        const data = response.data || [];
        setManufacturers(data);
      } else {
        toastify(response.error || 'Failed to fetch manufacturers', 'error');
        setManufacturers([]);
      }
    } catch (error) {
      toastify('An error occurred while fetching manufacturers', 'error');
      setManufacturers([]);
    }
  };

  const fetchIotCards = async () => {
    try {
      const response = await makeRequest2(waterMeterIotCardsBaseUrl, 'GET');

      if (response.success) {
        const data = response.data || [];
        // Transform the data to include concentrator serial number
        const transformedData = await Promise.all(data.map(async (card) => {
          const concentratorResponse = await makeRequest2(`${concentratorBaseUrl}/${card.concentrator}`, 'GET');
          return {
            ...card,
            concentratorSerialNumber: concentratorResponse.success ? concentratorResponse.data.serialNumber : 'N/A'
          };
        }));
        setIotCards(transformedData);
      } else {
        toastify(response.error || 'Failed to fetch IOT cards', 'error');
        setIotCards([]);
      }
    } catch (error) {
      console.error('Error in fetchIotCards:', error);
      setIotCards([]);
    }
  };
  // Handler functions for meter sizes
  const handleAddMeterSize = async () => {
    try {
      await makeRequest2(waterMeterSizeBaseUrl, 'POST', { code: newMeterSize });
      toastify('Meter size added successfully', 'success');
      setNewMeterSize('');
      fetchMeterSizes();
    } catch (error) {
      toastify('Error adding meter size', 'error');
    }
  };

  const searchConcentrator = (event) => {
    const query = event.query.toLowerCase();
    const filtered = concentrators.filter(concentrator =>
      concentrator.serialNumber.toLowerCase().includes(query) ||
      concentrator.manufacturer.toLowerCase().includes(query)
    );
    setFilteredConcentrators(filtered);
  };

  const handleDeleteMeterSize = (code) => {
    setItemToDelete(code);
    setDeleteType('meterSize');
    setDeleteDialogVisible(true);
  };

  // Handler functions for protocols
  const handleAddProtocol = async () => {
    try {
      await makeRequest2(waterMeterProtocolsBaseUrl, 'POST', { name: newProtocol });
      toastify('Protocol added successfully', 'success');
      setNewProtocol('');
      fetchProtocols();
    } catch (error) {
      toastify('Error adding protocol', 'error');
    }
  };


  const handleDeleteProtocol = (name) => {
    setItemToDelete(name);
    setDeleteType('protocol');
    setDeleteDialogVisible(true);
  };

  // Handler functions for manufacturers
  const handleAddManufacturer = async () => {
    try {
      await makeRequest2(waterMeterManufacturerBaseUrl, 'POST', manufacturerForm);
      toastify('Manufacturer added successfully', 'success');
      setManufacturerForm({
        name: '',
        country: '',
        contactPerson: '',
        phoneNumber: '',
        email: ''
      });
      fetchManufacturers();
    } catch (error) {
      toastify('Error adding manufacturer', 'error');
    }
  };

  const handleDeleteManufacturer = (id) => {
    setItemToDelete(id);
    setDeleteType('manufacturer');
    setDeleteDialogVisible(true);
  };

  const handleAddModalOpen = () => {
    setNewIotCardForm({
      serialNumber: '',
      provider: '',
      status: 'inactive'
    });
    setAddIotCardVisible(true);
  };

  const handleEditModalOpen = (iotCard) => {
    const concentrator = concentrators.find(c => c._id === iotCard.concentrator);
    setSelectedConcentrator(concentrator);
    setEditIotCardForm({
      serialNumber: iotCard.serialNumber || '',
      provider: iotCard.provider || '',
      concentrator: iotCard.concentrator || '',
      location: iotCard.location || '',
      status: iotCard.status || 'inactive'
    });
    setSelectedIotCard(iotCard);
    setEditIotCardVisible(true);
  };

  const handleAddSubmit = async () => {
    setLoading(true);
    try {
      await makeRequest2(waterMeterIotCardsBaseUrl, 'POST', {
        ...newIotCardForm,
        concentrator: null,
        location: null
      });
      toastify('IOT card added successfully', 'success');
      setAddIotCardVisible(false);
      fetchIotCards();
    } catch (error) {
      toastify('An error occurred while adding the IOT card', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async () => {
    setLoading(true);
    try {
      await makeRequest2(`${waterMeterIotCardsBaseUrl}/${selectedIotCard._id}`, 'PUT', editIotCardForm);
      toastify('IOT card updated successfully', 'success');
      setEditIotCardVisible(false);
      fetchIotCards();
    } catch (error) {
      toastify('An error occurred while updating the IOT card', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteIotCard = (id) => {
    setItemToDelete(id);
    setDeleteType('iotCard');
    setDeleteDialogVisible(true);
  };

  const actionsTemplate = (rowData) => (
    <div>
      <Button
        icon="ti ti-edit"
        className="p-button-rounded p-button-info mr-2"
        onClick={() => handleEditModalOpen(rowData)}
        tooltip="Edit"
      />
      <Button
        icon="ti ti-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => handleDeleteIotCard(rowData._id)}
        tooltip="Delete"
      />
    </div>
  );
  const handleConfirmDelete = async () => {
    try {
      switch (deleteType) {
        case 'meterSize':
          await makeRequest2(`${waterMeterSizeBaseUrl}/${itemToDelete}`, 'DELETE');
          toastify('Meter size deleted successfully', 'success');
          fetchMeterSizes();
          break;
        case 'protocol':
          await makeRequest2(`${waterMeterProtocolsBaseUrl}/${itemToDelete}`, 'DELETE');
          toastify('Protocol deleted successfully', 'success');
          fetchProtocols();
          break;
        case 'manufacturer':
          await makeRequest2(`${waterMeterManufacturerBaseUrl}/${itemToDelete}`, 'DELETE');
          toastify('Manufacturer deleted successfully', 'success');
          fetchManufacturers();
          break;
        case 'iotCard':
          await makeRequest2(`${waterMeterIotCardsBaseUrl}/${itemToDelete}`, 'DELETE');
          toastify('IOT card deleted successfully', 'success');
          fetchIotCards();
          break;
        default:
          toastify('Invalid delete operation', 'error');
          console.error('Unknown delete type:', deleteType);
          break;
      }
    } catch (error) {
      toastify(`Error deleting ${deleteType}`, 'error');
    } finally {
      setDeleteDialogVisible(false);
      setItemToDelete(null);
      setDeleteType('');
    }
  };

  const getDeleteMessage = () => {
    switch (deleteType) {
      case 'meterSize':
        return 'Are you sure you want to delete this meter size?';
      case 'protocol':
        return 'Are you sure you want to delete this protocol?';
      case 'manufacturer':
        return 'Are you sure you want to delete this manufacturer?';
      case 'iotCard':
        return 'Are you sure you want to delete this IOT card?';
      default:
        return 'Are you sure you want to delete this item?';
    }
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/core/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Water Meter Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Configs</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card mb-4">
            <div className="card-header p-3">
              <div className="card-body py-0">
                <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                      <i className="ti ti-viewfinder me-2"></i>
                      <span>Meter Sizes</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="false">
                      <i className="ti ti-brand-producthunt me-2"></i>
                      <span>Meter Communication Protocols</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="false">
                      <i className="ti ti-building me-2"></i>
                      <span>Meter Manufacturers</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="false">
                      <i className="ti ti-outlet me-2"></i>
                      <span>IOT Cards</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                {/* Meter Sizes Tab */}
                <div className="tab-pane show active" id="profile-1" role="tabpanel">
                  <div className="row g-4 p-4">
                    <div className="col-md-12 mb-3">
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <label>Size<span style={{ color: 'red' }}>*</span></label>
                                <input
                                  className="form-control mt-2"
                                  placeholder="Enter Meter Size e.g DN-15"
                                  value={newMeterSize}
                                  onChange={(e) => setNewMeterSize(e.target.value)}
                                />
                                <div className="mt-3" style={{ float: 'right' }}>
                                  <button className="btn btn-primary" onClick={handleAddMeterSize}>Submit</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <ul className="list-group">
                              {meterSizes.map((size, index) => (
                                <li className="list-group-item" key={size._id}>
                                  <span>
                                    {index + 1}. {size.code}
                                    <div style={{ float: 'right', color: 'red' }}>
                                      <Link to="#" style={{ color: 'red' }} onClick={() => handleDeleteMeterSize(size.code)}>
                                        <i className="ti ti-trash"></i>
                                      </Link>
                                    </div>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Protocols Tab */}
                <div className="tab-pane" id="profile-2" role="tabpanel">
                <div className="row g-4">
                <div className="col-md-12 mb-3">
                </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Communication Protocol<span style={{ color: 'red' }}>*</span></label>
                              <input
                                className="form-control mt-2"
                                placeholder="Enter Meter Communication Protocol e.g TCP"
                                value={newProtocol}
                                onChange={(e) => setNewProtocol(e.target.value)}
                              />
                              <div className="mt-3" style={{ float: 'right' }}>
                                <button className="btn btn-primary" onClick={handleAddProtocol}>Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <ul className="list-group">
                            {protocols.map((protocol, index) => (
                              <li className="list-group-item" key={protocol._id}>
                                <span>
                                  {index + 1}. {protocol.name}
                                  <div style={{ float: 'right', color: 'red' }}>
                                    <Link to="#" style={{ color: 'red' }} onClick={() => handleDeleteProtocol(protocol.name)}>
                                      <i className="ti ti-trash"></i>
                                    </Link>
                                  </div>
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>

                {/* Manufacturers Tab */}
                <div className="tab-pane" id="profile-3" role="tabpanel">
                <div className="row g-4">
                <div className="col-md-12 mb-3">
                </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                <label>Manufacturer<span style={{ color: 'red' }}>*</span></label>
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  placeholder="Enter Manufacturer Name"
                                  value={manufacturerForm.name}
                                  onChange={(e) => setManufacturerForm({ ...manufacturerForm, name: e.target.value })}
                                />
                              </div>

                              <div className="mt-2">
                                <label>Country<span style={{ color: 'red' }}>*</span></label>
                                <select
                                  className="form-control mt-2"
                                  value={manufacturerForm.country}
                                  onChange={(e) => setManufacturerForm({ ...manufacturerForm, country: e.target.value })}
                                >
                                  <option value="">-- Select --</option>
                                  {countries.map((country, index) => (
                                    <option key={index} value={country}>{country}</option>
                                  ))}
                                </select>
                              </div>

                              <div className="mt-2">
                                <label>Contact Person Name<span style={{ color: 'red' }}>*</span></label>
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  placeholder="Enter Person Name"
                                  value={manufacturerForm.contactPerson}
                                  onChange={(e) => setManufacturerForm({ ...manufacturerForm, contactPerson: e.target.value })}
                                />
                              </div>

                              <div className="mt-2">
                                <label>Contact Person Phone Number<span style={{ color: 'red' }}>*</span></label>
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  placeholder="Enter Person Phone Number"
                                  value={manufacturerForm.phoneNumber}
                                  onChange={(e) => setManufacturerForm({ ...manufacturerForm, phoneNumber: e.target.value })}
                                />
                              </div>

                              <div className="mt-2">
                                <label>Contact Person Email<span style={{ color: 'red' }}>*</span></label>
                                <input
                                  type="email"
                                  className="form-control mt-2"
                                  placeholder="Enter Person Email"
                                  value={manufacturerForm.email}
                                  onChange={(e) => setManufacturerForm({ ...manufacturerForm, email: e.target.value })}
                                />
                              </div>

                              <div className="mt-3" style={{ float: 'right' }}>
                                <button className="btn btn-primary" onClick={handleAddManufacturer}>Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <ul className="list-group">
                            {manufacturers.map((manufacturer) => (
                              <li className="list-group-item" key={manufacturer._id}>
                                <span><strong>Manufacturer:</strong> {manufacturer.name}</span>
                                <br />
                                <span><strong>Country:</strong> {manufacturer.country}</span>
                                <br />
                                <span><strong>Contact Person:</strong> {manufacturer.contactPerson}</span>
                                <br />
                                <span><strong>Contact Person Email:</strong> {manufacturer.email}</span>
                                <br />
                                <span><strong>Contact Person Phone Number:</strong> {manufacturer.phoneNumber}</span>
                                <div style={{ float: 'right' }}>
                                  <button
                                    className="btn btn-danger mt-2"
                                    onClick={() => handleDeleteManufacturer(manufacturer._id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>

                {/* IOT Cards Tab */}
                <div className="tab-pane" id="profile-4" role="tabpanel">
                <div className="row g-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div style={{ float: 'right' }}>
                        <button
                          className="btn btn-primary"
                          onClick={handleAddModalOpen}
                        >
                          Add IOT Card
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="dt-responsive table-responsive">
                            <DataTable
                              value={iotCards}
                              emptyMessage="No IOT cards found."
                              sortMode="multiple"
                              paginator
                              rows={10}
                              stripedRows
                              tableStyle={{ minWidth: '50rem' }}
                            >
                              <Column field="serialNumber" sortable header="Serial Number" />
                              <Column field="provider" header="Provider" />
                              <Column field="concentratorSerialNumber" header="Concentrator" sortable />
                              <Column field="location" header="Location" />
                              <Column field="status" header="Status" />
                              <Column header="Actions" body={actionsTemplate} />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add IOT Card Dialog */}
      <Dialog
        header="Add IOT Card"
        visible={addIotCardVisible}
        style={{ width: '40vw' }}
        onHide={() => setAddIotCardVisible(false)}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="mt-4">Serial Number<span style={{ color: 'red' }}>*</span></label>
              <InputText
                className="form-control mt-2"
                value={newIotCardForm.serialNumber}
                onChange={(e) => setNewIotCardForm({ ...newIotCardForm, serialNumber: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label className="mt-2">Provider<span style={{ color: 'red' }}>*</span></label>
              <select
                className="form-control mt-2"
                value={newIotCardForm.provider}
                onChange={(e) => setNewIotCardForm({ ...newIotCardForm, provider: e.target.value })}
              >
                <option value="">Select Provider</option>
                <option value="Safaricom">Safaricom</option>
                <option value="Telkom">Telkom</option>
                <option value="Airtel">Airtel</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group mt-3">
              <div style={{ float: 'right' }}>
                <Button
                  label={loading ? 'Saving...' : 'Submit'}
                  onClick={handleAddSubmit}
                  disabled={loading}
                  className="p-button-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Add the ConfirmDeleteDialog */}
      <ConfirmDeleteDialog
        visible={deleteDialogVisible}
        onHide={() => {
          setDeleteDialogVisible(false);
          setItemToDelete(null);
          setDeleteType('');
        }}
        message={getDeleteMessage()}
        onConfirm={handleConfirmDelete}
      />

      {/* Edit IOT Card Dialog */}
      <Dialog
        header="Edit IOT Card"
        visible={editIotCardVisible}
        style={{ width: '40vw' }}
        onHide={() => {
          setEditIotCardVisible(false);
          setSelectedIotCard(null);
          setSelectedConcentrator(null);
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="mt-4">Serial Number<span style={{ color: 'red' }}>*</span></label>
              <InputText
                className="form-control mt-2"
                value={editIotCardForm.serialNumber}
                onChange={(e) => setEditIotCardForm({ ...editIotCardForm, serialNumber: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label className="mt-2">Provider<span style={{ color: 'red' }}>*</span></label>
              <select
                className="form-control mt-2"
                value={editIotCardForm.provider}
                onChange={(e) => setEditIotCardForm({ ...editIotCardForm, provider: e.target.value })}
              >
                <option value="">Select Provider</option>
                <option value="Safaricom">Safaricom</option>
                <option value="Telkom">Telkom</option>
                <option value="Airtel">Airtel</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label className="mt-2">Concentrator<span style={{ color: 'red' }}>*</span></label>
              <AutoComplete
                value={selectedConcentrator}
                suggestions={filteredConcentrators}
                completeMethod={searchConcentrator}
                field="serialNumber"
                dropdown
                forceSelection
                className="w-100 mt-2"
                onChange={(e) => {
                  setSelectedConcentrator(e.value);
                  setEditIotCardForm({ ...editIotCardForm, concentrator: e.value?._id });
                }}
                itemTemplate={(item) => (
                  <div>
                    <div><strong>S/N:</strong> {item.serialNumber}</div>
                    <div><small>Manufacturer: {item.manufacturer}</small></div>
                  </div>
                )}
                placeholder="Search for a concentrator..."
              />
            </div>
            <div className="form-group">
              <label className="mt-2">Location<span style={{ color: 'red' }}>*</span></label>
              <InputText
                className="form-control mt-2"
                value={editIotCardForm.location}
                onChange={(e) => setEditIotCardForm({ ...editIotCardForm, location: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label className="mt-2">Status</label>
              <select
                className="form-control mt-2"
                value={editIotCardForm.status}
                onChange={(e) => setEditIotCardForm({ ...editIotCardForm, status: e.target.value })}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="suspended">Suspended</option>
              </select>
            </div>
            <div className="form-group mt-3">
              <div style={{ float: 'right' }}>
                <Button
                  label={loading ? 'Saving...' : 'Submit'}
                  onClick={handleEditSubmit}
                  disabled={loading}
                  className="p-button-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <ConfirmDialog />
    </Layout>
  );
}

export default Configs;